<template>
  <div :class="root_classes">
    <input v-if="name" type="hidden" :name="name" v-model="equipments">

    <div class="row mt-3">
      <div class="col">Hervorgehobene Ausstattung</div>
    </div>
    <div class="row">
      <template v-for="item in list_with_icons">
        <div :class="element_classes" :key="item.id">
          <label :for="'car_equip'.concat('_', item.id)"
                 class="mt-3 d-flex justify-content-center">{{ item.label }}</label>
          <custom-btn-checkbox
              :id="'car_equip'.concat('_', item.id)"
              :label="item.label"
              :custom_icon_src="item.icon_src"
              :title="item.label"
              v-model="pActive[item.id]"
              root_classes="d-flex justify-content-center"
              group_classes=""
              custom_icon_classes="btn-w-35"
              label_classes="btn-secondary btn-w-45 p-1"
          />
        </div>
      </template>
    </div>

    <div class="row mt-3">
      <div class="col pr-0 text-right">
        <span v-html="this.showAllEquipment ? 'Alle Ausstattung ausblenden' : 'Alle Ausstattung einblenden'"></span>
        <button class="btn btn-primary ml-1" type="button" @click="toggleShowAllEquipment">
          <i class="fas" :class="this.showAllEquipment ? 'fa-eye-slash' : 'fa-eye'"></i>
        </button>
      </div>
    </div>
    <div v-if="this.showAllEquipment" class="row mt-1">
      <template v-for="(item, index) in list_without_icons">
        <div class="col-12 col-md-6 col-lg-3 bg-platinum p-3 m-0 form-check form-check-inline" :key="item.id">
          <custom-btn-checkbox
              class="form-check-input"
              :id="'car_equip'.concat('_', item.id)"
              :label="item.label"
              :title="item.label"
              :toggle_icon_classes="['fa-check', '']"
              v-model="pActive[item.id]"
              label_classes="btn-secondary btn-w-40"
          />
          <label :for="'car_equip'.concat('_', item.id)"
                 class="form-check-label text-truncate">{{ item.label }}
          </label>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
import customBtnCheckbox from '../components/custom-btn-checkbox';

export default {
  name: "car-equipments-form",
  components: {customBtnCheckbox},
  props: {
    name: {type: String},
    list_with_icons: {type: Array},
    list_without_icons: {type: Array},
    value: {type: Array},
    pActive: {
      type: Object, default: function () {

        let activeObj = {},
            ref       = this;

        this.list_with_icons.forEach(function (element) {
          activeObj[element.id] = ref.value.includes(element.id);
        });
        this.list_without_icons.forEach(function (element) {
          activeObj[element.id] = ref.value.includes(element.id);
        });

        return activeObj;
      }
    },
    root_classes: {type: String},
    element_classes: {type: String}
  },
  data() {
    return {
      equipments: this.get_active_equipment(),
      showAllEquipment: false,
    }
  },
  computed: {},
  watch: {
    pActive: {
      immediate: true,
      handler: function (pActive) {
        this.equipments = this.get_active_equipment()
      },
      deep: true
    }
  },
  methods: {
    toggleShowAllEquipment() {
      this.showAllEquipment = !this.showAllEquipment;
    },

    get_active_equipment() {

      let activeEquipment = [],
          ref             = this;

      Object.keys(this.pActive).forEach(function (key) {
        if (ref.pActive[key] === true) {
          activeEquipment.push(key);
        }
      });

      return JSON.stringify(activeEquipment);
    }
  }
}
</script>

<style scoped>

</style>