<template>
  <div class="custom-vue-slider vue-slider-component">

    <input type="hidden" :name="hidden_name" v-model="value_min"/>
    <template v-if="is_range_slider || is_text_slider">
      <input type="hidden" :name="hidden_name" v-model="value_max"/>
    </template>
    <VueSlider v-if="is_range_slider" ref="slider" v-model="selection" v-bind="slider_options"></VueSlider>
    <VueSlider v-if="is_text_slider" ref="slider" v-model="selection" :data="this.slider_options.delivery_data"
               :lazy="this.slider_options.lazy" :hide-label="true" :data-value="'value'" :data-label="'label'"
               :tooltip="this.slider_options.tooltip"
    >
    </VueSlider>
    <template v-if="has_labels">
      <div style="float:left">{{ slider_options.min }}</div>
      <div style="float:right">{{ slider_options.max }}</div>
    </template>
  </div>
</template>
<script>
import VueSlider from 'vue-slider-component'

export default {
  name: "custom-vue-slider",
  props: {
    slider_options: null,
    value: null,
    name: null,
    has_labels: {type: Boolean, default: false},
    is_range_slider: {type: Boolean, default: false},
    is_text_slider: {type: Boolean, default: false}
  },
  components: {
    VueSlider
  },
  data() {
    if (!this.is_text_slider) {
      if (this.value === null || typeof (this.value) === "undefined") {
        if (this.is_range_slider) {
          return {
            selection: [this.slider_options['min'], this.slider_options['max']]
          }
        } else {
          return {
            selection: this.slider_options['min']
          }
        }
      }

      if (this.is_range_slider && Array.isArray(this.value) && this.value.length <= 0) {
        return {
          selection: [this.slider_options['min'], this.slider_options['max']]
        }
      }

      if (this.is_range_slider && (!Array.isArray(this.value) || this.value.length !== 2)) {
        throw 'Range slider expects value to be: [Number, Number]!'
      } else if (!this.is_range_slider && Array.isArray(this.value)) {
        throw 'Common slider expects value to be: Number!'
      }

      return {
        selection: this.value || [this.slider_options['min'], this.slider_options['max']]
      };
    } else {
      if ((this.value === null || typeof (this.value) === "undefined") || (Array.isArray(this.value) && this.value.length <= 0)) {
        return {
          selection: [_.head(this.slider_options.delivery_data).value, _.last(this.slider_options.delivery_data).value]
        };
      } else {
        return {
          selection: this.value || [_.head(this.slider_options.delivery_data).value, _.last(this.slider_options.delivery_data).value]
        };
      }
    }
  },
  computed: {
    value_min() {
      return this.selection[0]
    },
    value_max() {
      return (this.is_range_slider || this.is_text_slider) ? this.selection[1] : null
    },
    hidden_name() {
      return (this.is_range_slider || this.is_text_slider) ? this.name + "[]" : this.name
    }
  },
  watch: {
    value(value) {
      this.selection = value;
    },
    selection(selection) {
      this.$emit('input', selection);
    }
  },
  methods: {}

}
</script>
