FIXME jwidderich: When the prop 'value' is changed (in the parent component), this component does not reflect the change
The percent-input.vue does this correctly.

<template>
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text">{{displayCurrency}}</span>
    </div>
    <numeric-input v-model="internalValue" :precision="precision" :min="min" :max="max" :separator="separator"
                     :delimiter="delimiter" :locale="locale" v-bind="$attrs" class="form-control form-control-sm"/>
    <div v-if="requestRunning || convertedValue !== null || requestError" class="input-group-append">
      <span class="input-group-text input-group-text-currency">
        <span v-if="requestRunning" class="loader"></span>
        <small v-else-if="convertedValue !== null">≈ {{this.convertOptions.to_code}}<br>{{convertedValue}}</small>
        <span v-else-if="requestError">⚠</span>
      </span>
    </div>
  </div>
</template>

<script>
  import NumericInput from './numeric-input';
  import _ from 'lodash';

  function updateConvertedValue(value) {
    if (!this.currencySymbol || !value || !this.convertOptions.to_code) {
      this.convertedValue = null;
      this.requestError = false;
      return;
    }
    if (this.convertOptions.fixed_rates && (!this.convertOptions.fixed_code || !this.convertOptions.fixed_rate && !this.convertOptions.fixed_at)) {
      this.convertedValue = null;
      this.requestError = false;
      return;
    }

    if(this.currencySymbol === this.convertOptions.to_code) {
      this.convertedValue = null;
      this.requestError = false;
      return;
    }

    let params = {
      code: this.currencySymbol,
      amount: value,
      to_code: this.convertOptions.to_code
    };
    if (this.convertOptions.fixed_rates) {
      params.fixed_code = this.convertOptions.fixed_code;
      params.fixed_rate = this.convertOptions.fixed_rate;
      params.fixed_at = this.convertOptions.fixed_at;
    }
    this.requestRunning = true;
    const request = $.get(`/currency_exchange_rates/convert`, params);
    request.done((result) => {
      const convertedCurrency = this.convertedCurrencyFromResult(result);
      this.convertedValue = convertedCurrency ? convertedCurrency.amount : null;
      this.requestError = false;
    });
    request.fail(() => {
      this.convertedValue = null;
      this.requestError = true;
    });
    request.always(() => this.requestRunning = false);
  }

  export default {
    inheritAttrs: false, // doesn't affect 'class' and 'style' bindings, because "It's better that way".
    props: {
      value: {required: true},
      precision: {required: false, default: 2},
      min: {required: false},
      max: {required: false},
      separator: {type: String, required: true}, // decimal symbol
      delimiter: {type: String, required: true}, // thousands separator
      locale: {type: String, required: false, default: undefined},

      currencySymbol: {type: [String, Number]},
      convertOptions: {type: [Object], required: true}
    },
    components: {NumericInput},
    data() {
      return {
        internalValue: this.value,
        requestRunning: false,
        requestError: false,
        convertedValue: null
      }
    },
    methods: {
      format(number) {
        return number.toLocaleString(this.locale, {minimumFractionDigits: 0, maximumFractionDigits: 0});
      },
      convertedCurrencyFromResult(result) {
        return result.to_currencies[this.convertOptions.to_code];
      }
  },
    computed: {
      displayCurrency() {
        return this.currencySymbol || '¤';
      }
    },
    watch: {
      value(newValue, oldValue) {
        if(!_.isEqual(newValue, oldValue)) {
          this.internalValue = newValue;
        }
      },
      internalValue(newValue) {
        this.$emit('input', newValue);
        this.updateConvertedValue(newValue);
      },
      currencySymbol(newValue, oldValue) {
        this.updateConvertedValue(this.value);
      },
      convertOptions: {
        handler(newValue, oldValue) {
          if(!_.isEqual(newValue, oldValue)) {
            this.updateConvertedValue(this.value);
          }
        },
        deep: true
      }
    },
    created() {
      // setting the updateConvertedValue in the methods block would mean that all currency-inputs share a single
      // debounced method.
      this.updateConvertedValue = _.debounce(updateConvertedValue, 250).bind(this);
      this.updateConvertedValue(this.value);
    }
  }
</script>
