<template>
  <div class="modal Modal" v-bind:class=modal_classes @click.self="close()">
    <div class="modal-dialog Modal-dialog" v-bind:class=dialog_classes>
      <div class="modal-content Modal-content" v-bind:class=content_classes>
        <div ref="alertMessage" class="d-none alert alert-danger mx-3 mt-3"></div>
        <div class="modal-header Modal-header" v-bind:class=header_classes>
          <slot name="header">{{header}}</slot>
          <button type="button" class="close Modal-close" aria-label="Close" @click="close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body Modal-body" v-bind:class=body_classes>
          <slot name="body">{{body}}</slot>
        </div>
        <div class="modal-footer Modal-footer" v-bind:class=footer_classes>
          <slot name="footer">{{footer}}</slot>
          <div ref="btnContainer" v-if="no_button === false">
            <template v-for="(button, i) in buttons">
              <button v-if="button === BUTTON_CLOSE" :class="btnClasses(i)" @click="close()">Schließen</button>
              <!--<button v-if="button === BUTTON_CLOSE" :class="btnClasses(i)" @click="close()">{{t('modal.action.close')}}</button>-->
              <button v-else-if="button === BUTTON_CANCEL" :class="btnClasses(i)" @click="close()">Abbrechen</button>
              <!--<button v-else-if="button === BUTTON_CANCEL" :class="btnClasses(i)" @click="close()">{{t('modal.action.cancel')}}</button>-->
              <button v-else :class="btnClasses(i, button.classes)" @click="onClick(button.action)">{{button.text}}</button>
            </template>
          </div>
          <div ref="progressThrobber" class="d-none align-items-center">
            <strong class="mr-3">Wird bearbeitet...</strong>
            <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ModalControl from '../../packs/modal-control';

  const btnClasses = {
    primary:    'btn btn-primary',
    secondary:  'btn btn-secondary'
  };

  export default {
    props: {
      header:           {type: String},
      body:             {type: String},
      footer:           {type: String},
      buttons:          {type: Array, default: () => [ModalControl.BUTTON_CLOSE]},
      no_button:        {type: Boolean, default: false},
      modal_classes :   {type: Array},
      dialog_classes :  {type: Array},
      content_classes : {type: Array},
      header_classes :  {type: Array},
      body_classes :    {type: Array},
      footer_classes :  {type: Array}
    },
    data() {
      return {
        BUTTON_CLOSE: ModalControl.BUTTON_CLOSE,
        BUTTON_CANCEL: ModalControl.BUTTON_CANCEL
      }
    },
    computed: {

    },
    methods: {
      close(payload) {
        this.$emit('close', payload);
      },
      onClick(callback) {
        callback(this.close, this);
      },
      btnClasses(i, overrideClasses) {
        if(overrideClasses) {
          return overrideClasses;
        }
        return (i === this.buttons.length - 1) ? btnClasses.primary : btnClasses.secondary;
      },
      showAlertMessage( msg ) {
        this.$refs.alertMessage.innerHTML = msg;
        this.$refs.alertMessage.classList.remove('d-none');
      },
      showProgressThrobber() {
        this.$refs.btnContainer.classList.add('d-none');
        this.$refs.progressThrobber.classList.add('d-flex');
        this.$refs.progressThrobber.classList.remove('d-none');
      },
      hideProgressThrobber() {
        this.$refs.btnContainer.classList.remove('d-none');
        this.$refs.progressThrobber.classList.add('d-none');
        this.$refs.progressThrobber.classList.remove('d-flex');
      }
    }
  }
</script>
