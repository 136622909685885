<template>
    <modal @close='close' :buttons=buttons>
        <template slot="header"><h3>{{title}}</h3></template>
        <template slot="body">
            <div>
                <div v-html="question"></div>
                <div class="mt-3">
                    <span class="text-uppercase">{{select_title}}</span>
                    <select class="form-control" @change="change">
                        <option v-for="(option, index) in jOptions" :value="option[1]" :selected="selected === option[1]">{{option[0]}}</option>
                    </select>
                </div>
            </div>
        </template>
        <template slot="footer"></template>
    </modal>
</template>

<script>
  import Modal from './modal';
  import ModalControl from '../../packs/modal-control';

  export default {
    components: {Modal},
    name: "modal-confirm",
    props: {
      modal_classes: {type: String},
      footer_classes: {type: String},
      title: {type: String},
      question: {type: String},
      buttons: {
        type: Array, default: () => [ModalControl.BUTTON_CLOSE]
      },
      select_title: {type:String, default: 'Bitte wählen Sie:'},
      preselect: {type:Number},
      options: {type: String}
    },
    data() {
      return {
        jOptions: JSON.parse(this.options),
        selected: this.preselect > 0 ? this.preselect : JSON.parse(this.options)[0][1]
      }
    },
    methods: {
      close() {
        this.$emit('close');
      },

      change(e){
        this.selected = e.target.selectedOptions[0].value;
      }
    }
  }
</script>
