/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.slim.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import Vue from 'vue'
import Foo from '../components/foo';
import ModalContainer from '../components/modals/modal-container';
import OfferFilterForm from '../components/offer-filter-form';
import OfferEditForm from '../components/offer-edit-form';
import OfferPriceSelector from '../components/offer-price-selector';
import OfferSearchForm from '../components/offer-search-form';
import DealerFilterForm from '../components/dealer-filter-form';
import DealerEditForm from '../components/dealer-edit-form';
import UserFilterForm from '../components/user-filter-form';
import SeoTextEditForm from '../components/seo-text-edit-form';
import SeoTextFilterForm from '../components/seo-text-filter-form';
import LeasingOfferRequestFilterForm from '../components/leasing-offer-request-filter-form';
import CarImportFilterForm from '../components/car-import-filter-form';
import UserSearchForm from '../components/user-search-form';
import NewsletterForm from '../components/newsletter-form';
import HubspotForm from '../components/hubspot-form';
import ShareIcons from '../components/share-icons';
import ContactRequestForm from '../components/contact-request-form';

import axios from 'axios';
import VueAxios from 'vue-axios';
import Rollbar from 'vue-rollbar';

axios.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0].getAttribute('content');
axios.defaults.headers.common['Accept'] = 'application/json';
Vue.use(VueAxios, axios);

let rollbarConfig = {
  enabled: false
};
let rollbarMetaTag = document.getElementById("X-ROLLBAR-CONFIG") ;
if(rollbarMetaTag){
  rollbarConfig = JSON.parse(rollbarMetaTag.getAttribute("content"));
}
Vue.use(Rollbar, rollbarConfig);



// See https://github.com/vuejs/vue/pull/7765, especially https://github.com/vuejs/vue/pull/7765#issuecomment-450423632
// This allows passing on slots to child components
Vue.prototype._b = (function (bind) {
  return function(data, tag, value, asProp, isSync) {
    if (value && value.$scopedSlots) {
      data.scopedSlots = value.$scopedSlots;
      delete value.$scopedSlots;
    }
    return bind.apply(this, arguments);
  };
})(Vue.prototype._b);

document.addEventListener('DOMContentLoaded', () => {


  /**
   * MODAL CONTAINER
   *
   * @type {Vue | CombinedVueInstance<V, *, {}, object, Record<never, any>>}
   */
  const modalContainerVue = new Vue({
    components: {ModalContainer},
    data() {
      return { stack: this.stack }
    },
    computed: {},
    methods: {}
  });
  // modalContainerVue.$mount(document.getElementById('modalContainer'));
  mountVUEComponent( modalContainerVue, document.getElementById('modalContainer') );


  /**
   * OFFER FILTER FORM
   *
   * @type {Vue | CombinedVueInstance<Vue, *, {}, object, Record<never, any>>}
   */
  const offerFilterFormVue = new Vue({
    components: { OfferFilterForm },
    data() {
      return { stack: this.stack }
    },
    computed: {},
    methods: {}
  });
  // offerFilterFormVue.$mount(document.getElementById('offerFilterForm'));
  mountVUEComponent( offerFilterFormVue, document.getElementById('offerFilterForm') );


  /**
   * OFFER EDIT FORM
   *
   * @type {Vue | CombinedVueInstance<Vue, *, {}, object, Record<never, any>>}
   */
  const offerEditFormVue = new Vue({
    components: { OfferEditForm },
    data() {
      return { stack: this.stack }
    },
    computed: {},
    methods: {}
  });
  mountVUEComponent( offerEditFormVue, document.getElementById('offerEditForm') );


  /**
   * OFFER PRICE SELECTOR
   *
   * @type {Vue | CombinedVueInstance<Vue, *, {}, object, Record<never, any>>}
   */
  const offerPriceSelectorVue = new Vue({
    components: { OfferPriceSelector },
    data() {
      return { stack: this.stack }
    },
    computed: {},
    methods: {}
  });
  mountVUEComponent( offerPriceSelectorVue, document.getElementById('offer-price-selector') );


  /**
   * OFFER SEARCH FORM
   *
   * @type {Vue | CombinedVueInstance<Vue, *, {}, object, Record<never, any>>}
   */
  const offerSearchFormVue = new Vue({
    components: { OfferSearchForm },
    data() {
      return { stack: this.stack }
    },
    computed: {},
    methods: {}
  });
  mountVUEComponent( offerSearchFormVue, document.getElementById('offer-search-form') );


  /**
   * DEALER FILTER FORM
   *
   * @type {Vue | CombinedVueInstance<Vue, *, {}, object, Record<never, any>>}
   */
  const dealerFilterFormVue = new Vue({
    components: { DealerFilterForm },
    data() {
      return { stack: this.stack }
    },
    computed: {},
    methods: {}
  });
  mountVUEComponent( dealerFilterFormVue, document.getElementById('dealer-filter-form') );


  /**
   * DEALER EDIT FORM
   *
   * @type {Vue | CombinedVueInstance<Vue, *, {}, object, Record<never, any>>}
   */
  const dealerEditFormVue = new Vue({
    components: { DealerEditForm },
    data() {
      return { stack: this.stack }
    },
    computed: {},
    methods: {}
  });
  mountVUEComponent( dealerEditFormVue, document.getElementById('dealer-edit-form') );


  /**
   * USER FILTER FORM
   *
   * @type {Vue | CombinedVueInstance<Vue, *, {}, object, Record<never, any>>}
   */
  const userFilterFormVue = new Vue({
    components: { UserFilterForm },
    data() {
      return { stack: this.stack }
    },
    computed: {},
    methods: {}
  });
  mountVUEComponent( userFilterFormVue, document.getElementById('user-filter-form') );


  /**
   * SEO TEXT FILTER FORM
   *
   * @type {Vue | CombinedVueInstance<Vue, *, {}, object, Record<never, any>>}
   */
  const seoTextFilterFormVue = new Vue({
    components: { SeoTextFilterForm },
    data() {
      return { stack: this.stack }
    },
    computed: {},
    methods: {}
  });
  mountVUEComponent( seoTextFilterFormVue, document.getElementById('seo-text-filter-form') );


  /**
   * SEO TEXT EDIT FORM
   *
   * @type {Vue | CombinedVueInstance<Vue, *, {}, object, Record<never, any>>}
   */
  const seoTextEditFormVue = new Vue({
    components: { SeoTextEditForm },
    data() {
      return { stack: this.stack }
    },
    computed: {},
    methods: {}
  });
  mountVUEComponent( seoTextEditFormVue, document.getElementById('seo-text-edit-form') );


  /**
   * LEASING OFFER REQEUST FILTER FORM
   *
   * @type {Vue | CombinedVueInstance<Vue, *, {}, object, Record<never, any>>}
   */
  const leasingOfferRequestFilterFormVue = new Vue({
    components: { LeasingOfferRequestFilterForm },
    data() {
      return { stack: this.stack }
    },
    computed: {},
    methods: {}
  });
  mountVUEComponent( leasingOfferRequestFilterFormVue, document.getElementById('leasing-offer-request-filter-form') );


  /**
   * CAR IMPORT FILTER FORM
   *
   * @type {Vue | CombinedVueInstance<Vue, *, {}, object, Record<never, any>>}
   */
  const carImportFilterFormVue = new Vue({
    components: { CarImportFilterForm },
    data() {
      return { stack: this.stack }
    },
    computed: {},
    methods: {}
  });
  mountVUEComponent( carImportFilterFormVue, document.getElementById('car-import-filter-form') );


  /**
   * USER SEARCH FORM
   *
   * @type {Vue | CombinedVueInstance<Vue, *, {}, object, Record<never, any>>}
   */
  const userSearchFormVue = new Vue({
    components: { UserSearchForm },
    data() {
      return { stack: this.stack }
    },
    computed: {},
    methods: {}
  });
  mountVUEComponent( userSearchFormVue, document.getElementById('user-search-form') );



  /**
   * USER SEARCH FORM
   *
   * @type {Vue | CombinedVueInstance<Vue, *, {}, object, Record<never, any>>}
   */
  let newsLetterElements = document.getElementsByTagName('newsletter-form');
  let newsLetterArr = Array.prototype.slice.call( newsLetterElements )
  newsLetterArr.forEach((ns) => {
    const newsletterForm = new Vue({
      components: { NewsletterForm },
      data() {
        return { stack: this.stack }
      },
      computed: {},
      methods: {}
    });
    mountVUEComponent( newsletterForm, ns );
  });
  const newsletterForm2 = new Vue({
    components: { NewsletterForm },
    data() {
      return { stack: this.stack }
    },
    computed: {},
    methods: {}
  });
  mountVUEComponent( newsletterForm2, document.getElementById('newsletter-form-2') );

  const hubspotForm = new Vue({
    components: { HubspotForm },
    data() {
      return { stack: this.stack }
    },
    computed: {},
    methods: {}
  });
  mountVUEComponent( hubspotForm, document.getElementById('hubspot-form') );



  const shareIcons = new Vue({
    components: { ShareIcons },
    data() {
      return { stack: this.stack }
    },
    computed: {},
    methods: {
      initializeTooltip() {
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="tooltip"]').on('shown.bs.tooltip', function () {
          setTimeout(function () {
            $('[data-toggle="tooltip"]').tooltip('hide');
          }, 1000);
        });
      }
    }
  });
  mountVUEComponent( shareIcons, document.getElementById('share-icons') );
  shareIcons.initializeTooltip();

  const contactRequestForm = new Vue({
    components: { ContactRequestForm },
    data() {
      return { stack: this.stack }
    },
    computed: {},
    methods: {}
  });
  mountVUEComponent( contactRequestForm, document.getElementById('contact-request-form') );





  /**
   * HELPER METHOD to mount VUE App on page.
   *
   * @param vueApp
   * @param htmlMountPoint
   */
  function mountVUEComponent( vueApp, htmlMountPoint ) {
    if ( htmlMountPoint === null || typeof(htmlMountPoint) === 'undefined' ) {
      // ignore if html mount point doesn't exist (= is not present on current page)
      return;
    }
    vueApp.$mount( htmlMountPoint );
  }

});


// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>


// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: () => {
//       return {
//         message: "Can you say hello?"
//       }
//     },
//     components: { App }
//   })
// })
