<template>
  <div class="bg-light my-4 d-flex align-items-center py-3 w-30">
    <div class="container share-icons">
      <ul class="d-flex align-items-center share-icons-list p-0 m-0">
        <li class="share-item">
          <i class="fas fa-share-alt text-primary share-icon d-block"></i>
        </li>
        <li class="share-item">
          <a href="javascript:void(0);" @click="facebookUrl" class="share-icon" title="Auf Facebook teilen">
            <img src="/share_icons/facebook.png" alt="Facebook" class="share-img d-block">
          </a>
        </li>
        <li class="share-item">
          <a href="javascript:void(0);" @click="linkedInUrl" class="share-icon">
            <img src="/share_icons/linkedin.png" alt="LinkedIn" class="share-img d-block" title="Auf LinkedIn teilen">
          </a>
        </li>
        <li class="share-item">
          <a href="javascript:void(0);" @click="mailShare" class="share-icon">
            <img src="/share_icons/email.png" alt="Email" class="share-img d-block" title="Per E-Mail versenden">
          </a>
        </li>
        <li class="share-item">
          <a title="Link kopiert!" id="share-copy-link" href="javascript:void(0);" @click="shareIconCopy" class="share-icon" data-toggle="tooltip" data-placement="top" data-trigger="click">
            <img src="/share_icons/copy.png" alt="Copy" class="share-img d-block">
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "share-icons",
  props: {
    url: {type: String},
    email_subject: {type: String}
  },
  data() {
    return {}
  },
  methods: {
    shareIconCopy() {
      var $temp = document.createElement("input");
      var $url = window.location.href;
      $temp.value = $url
      document.body.appendChild($temp);
      $temp.select();
      document.execCommand("copy");
      $temp.remove();
    },
    facebookUrl() {
      this.openNewWindow("https://www.facebook.com/sharer/sharer.php?u=" + encodeURI(this.url));
    },
    linkedInUrl() {
      this.openNewWindow("https://www.linkedin.com/shareArticle?url=" + encodeURI(this.url) + "&title=" + encodeURI(this.email_subject) + "&source=GetYourDrive");
    },
    mailShare() {
      this.openNewWindow("mailto:?Subject=" + encodeURI(this.email_subject) + "&body=" + encodeURI(this.url));
    },
    openNewWindow(url) {
      window.open(url, 'name', 'width=600,height=400')
    }
  }
}
</script>
