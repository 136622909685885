<script>
  import _ from 'lodash';
  import CustomBtnCheckbox from "../components/custom-btn-checkbox";
  import CustomVueSelect from "../components/custom-vue-select";
  import Uploader from "../utils/uploader";
  import {DirectUpload} from "@rails/activestorage";

  export default {
    components: {CustomBtnCheckbox, CustomVueSelect},
    props: {
      pAttachments: {type: Array, required: true},
      blueprint: {type: Object, required: true}
    },
    data() {
      return {
        attachments: _.cloneDeep(this.pAttachments),
      };
    },
    computed: {
      requestsRunning() {
        return this.attachments.some(i => i.uploader && i.uploader.running);
      }
    },
    methods: {
      correctNameIndex(name, index) {
        return name.replace(/\[\d+\]/, "[".concat(index, ']'));
      },

      correctIdIndex(id, index) {
        return id.replace(/_\d+_/, "_".concat(index, '_'));
      },
      onAttachmentFilesChange(event) {
        const files = Array.from(event.target.files);

        const url = event.target.dataset.directUploadUrl;
        files.forEach(file => this.addAttachment(file, url));
        event.target.value = null;
      },
      addAttachment(file, url) {
        console.log('addAttachment!');
        const newAttachment = _.cloneDeep(this.blueprint);
        newAttachment.uploader = new Uploader(file, url, 'file');
        newAttachment.key = `KEY_${Math.round(Math.random() * 1000000000)}`;
        console.log('addAttachment! ', newAttachment);

        this.attachments.unshift(newAttachment);
      },
      removeAttachment(attachment, index) {
        if(attachment.id) {
          attachment._destroy = this.attachmentMarkedForDeletion(attachment) ? '0' : '1';
        } else {
          this.attachments.splice(index, 1);
        }
      },
      attachmentMarkedForDeletion(attachment) {
        return attachment._destroy === '1';
      },
      attachmentOrderOptions(){
          return this.attachments.map((el, index) => (
              {
                  label: index + 1,
                  value: index + 1
              }
          ))
      },
      onAttachmentOrderChange(index, $event){
          // console.log(index, $event);
          this.$nextTick(() => {
              let attachment = this.attachments.splice(index, 1);
              this.attachments.splice($event, 0, attachment[0]);
          });
      }
    },

    watch: {
      requestsRunning(newValue) {
        this.$emit('requestsRunning', newValue);
      }
    }
  }
</script>