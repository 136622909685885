<template>
  <div class="input-group date-time-input" v-bind:class=root_classes>
    <FlatPickr
      :value="selectedDate"
      @input="onInput"
      @on-close="onClose"
      :config="config"
      v-bind:class=pickr_classes
      ref="fPickr"
      :name="name"
    />
    <div class="input-group-append">
      <div v-if="with_clear_btn" class="input-group-text btn btn-secondary" style="cursor: pointer;" @click="onClear()"><i class="fas fa-times"></i></div>
    </div>
  </div>
</template>

<script>
  import FlatPickr from 'vue-flatpickr-component';
  import FlatPickrLocale_DE from 'flatpickr/dist/l10n/de';
  import FlatPickrLocale_EN from 'flatpickr/dist/l10n/default';
  import moment from 'moment';

  const TYPE_DATE = 'date';
  const TYPE_MONTH_DATE = 'month_date';
  const TYPE_DATE_TIME = 'datetime';
  const TYPES = [TYPE_DATE, TYPE_MONTH_DATE, TYPE_DATE_TIME];

  const LOCALES = {
    de: FlatPickrLocale_DE.de,
    en: FlatPickrLocale_EN
  };

  // This is the format that FlatPickr emits the date in
  const DATE_FORMATS = {
    [TYPE_DATE]: 'Y-m-d',
    [TYPE_MONTH_DATE]: 'Y-m-d',
    [TYPE_DATE_TIME]: 'Y-m-dTH:i:S\\Z'
  };

  // These two formats are used by FlatPickr as the display format (the terminology is a bit weird)
  const ALT_FORMATS_DATE = {
    de: "d.m.Y",
    en: "d.m.Y",
  };
  const ALT_FORMATS_MONTH_DATE = {
    de: "m.Y",
    en: "m.Y",
  };
  const ALT_FORMATS_DATE_TIME = {
    de: "d.m.Y H:i",
    en: "d.m.Y H:i",
  };

  export default {
    props: {
      value: {type: String},
      locale: {
        validator: (locale) => !!LOCALES[locale] || console.error(`invalid locale ${locale}: available locales are ${Object.keys(LOCALES)}`),
        required: true
      },
      type: {
        validator: (type) => TYPES.indexOf(type) !== -1 || console.error(`invalid type ${type}: must be one of ${TYPES}`),
        required: true
      },
      name: {type: String},
      with_clear_btn: {type: Boolean, default: false},
      pickr_classes: {type: String},
      root_classes: {type: String}
    },
    components: {FlatPickr},
    data() {
      return {
        config: {
          dateFormat: this.dateFormat(),
          altInput: true,
          altFormat: this.altFormat(),
          allowInput: true,
          enableTime: this.enableTime(),
          defaultHour: 0,
          defaultMinute: 0,
          time_24hr: true,
          locale: LOCALES[this.locale]
        }
      }
    },
    methods: {
      onClose(date, dateString, flatPickr) {
        // Also set the date when the input loses focus
        flatPickr.setDate(flatPickr._input.value, true, this.config.altFormat);
      },
      onInput(date) {
        this.$emit('input', date);
      },
      dateFormat() {
        return DATE_FORMATS[this.type];
      },
      altFormat() {

        switch ( this.type ) {
          case TYPE_MONTH_DATE:
            return ALT_FORMATS_MONTH_DATE[this.locale];
            break;
          case TYPE_DATE_TIME:
            return ALT_FORMATS_DATE_TIME[this.locale];
            break;
          case TYPE_DATE:
          default:
            return ALT_FORMATS_DATE[this.locale];
            break;
        }
      },
      enableTime() {
        return this.type === TYPE_DATE_TIME;
      },
      onClear() {
        // Clean up flatpickr value
        this.$refs.fPickr.fp.setDate('', true, this.config.altFormat);
      }
    },
    computed: {
      selectedDate() {
        if(this.type === TYPE_DATE || this.type === TYPE_MONTH_DATE) {
          return this.value;
        }
        const momentDate = moment(this.value, ["YYYY-MM-DDTHH:mm:ss.SSSZ", "YYYY-MM-DDTHH:mm:ssZ"], true);
        if(momentDate.isValid()) {
          /*
            The date passed in from rails should be in the format "YYYY-MM-DDTHH:mm:ss.SSSZ" (e.g. 2018-03-01T00:00:00.000Z)
            Even though tis is technically in UTC, we need to set the offset to 0 so moment.js thinks this is in UTC, too.
            This is done with utcOffset(0). The date is then formatted to "YYYY-MM-DDTHH:mm:ss" (note the missing timezone).
            This is required because flatpickr uses the Javascript date for formatting, which converts to local time before
            returning any value like the current date, hour or minute. As an example, consider:
            in a browser with a timezone UTC +1
            new Date("2018-03-01T00:00:00.000Z").getHours() // => 1
            new Date("2018-03-01T00:00:00.000").getHours() // => 0
           */
          return momentDate.utcOffset(0).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
        } else {
          return null;
        }
      }
    }
  }

</script>
