<template>

      <div :class="root_class">
        <input
                @blur="onBlur"
                @input="onInput"
                @focus="onFocus"

                v-bind="$attrs"
                :class="input_class"

                v-model="displayValue"
        >
        <input type="hidden" :name="name" v-model="inputValue">
      </div>
</template>

<script>
  import _ from 'lodash';


  //const hiddenProps =
    //      _(this.$attrs).map('props').filter().apply()

  //const input_props = _(this.$attrs).filter().apply();

  export default {
    inheritAttrs: false,
    props: {
      value: {required: true},
      name: {},
      precision: {required: false, default: 2},
      min: {required: false, default: 0},
      max: {required: false, default:  999999999999999},
      separator: {required: false, default: '.'}, // decimal symbol
      delimiter: {required: false, default: ','}, // thousands separator
      locale: {type: String, required: false, default: undefined},
      input_class: {type: String, required: false},
      root_class: {type: String, required: false},
    },
    data() {
      return {
        displayValue: '',
        preventDisplayUpdates: false,
        inputValue: this.value
      }
    },
    computed: {
      deFormatRegex() {
        return new RegExp(`[^\\d${this.separator}+-]`, 'g')
      },
      formattedValue() {
        return this.format(this.value);
      }
    },
    watch: {
      formattedValue: {
        immediate: true,
        handler() {
          if(!this.preventDisplayUpdates) {
            this.displayValue = this.formattedValue;
            this.applyNewValue();
          }
        }
      }
    },
    methods: {
      onFocus() {
        this.preventDisplayUpdates = true;
      },
      onBlur(event) {
        this.$emit('blur', event);
        this.preventDisplayUpdates = false;
        this.displayValue = this.formattedValue;
      },
      onInput() {
        this.applyNewValue();
        this.$emit('input', this.inputValue, this);
      },
      applyNewValue() {
        let value = this.deFormat(this.displayValue);
        if(_.isFinite(value)) {
          value = _.clamp(value, this.min, this.max);
        }
        this.inputValue = value;
      },
      format(number) {
        if (!_.isFinite(number)) {
            // give it a try to cast as number (needed to (easily) use floats from ruby, as ruby converts them to string when serializing JSON)
            if (_.isFinite(Number(number))) {
              return Number(number).toLocaleString(this.locale, {minimumFractionDigits: this.precision, maximumFractionDigits: this.precision});
            }
          return '';
        } else {
          return number.toLocaleString(this.locale, {minimumFractionDigits: this.precision, maximumFractionDigits: this.precision});
        }
      },
      deFormat(formatted) {
        if (!_.isString(formatted)) {
          return null;
        } else {
          const cleanString = formatted.replace(this.deFormatRegex, '').replace(this.separator, '.');
          if(!cleanString.match(/^[-+]?\d+(\.\d*)?$/)) {
            return null;
          }
          const number = parseFloat(cleanString);

          return _.round(number, this.precision);
        }
      }
    }
  }
</script>
