import { render, staticRenderFns } from "./percent-input.vue?vue&type=template&id=4484567c&"
import script from "./percent-input.vue?vue&type=script&lang=js&"
export * from "./percent-input.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports