<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <label>Alle Leasingraten ersetzen</label>
      <textarea rows="6" class="form-control" v-model="textarea"></textarea>
    </div>
    <div class="col-12 col-md-6">
      <label>Status</label>
      <div v-if="!isValid">Fehler: {{errorMessage}}</div>
      <div v-else-if="!prices">Keine Eingabe</div>
      <div v-else>{{ prices.length }} Leasingraten erkannt</div>
      <input type="submit" value="Leasingraten übernehmen" class="btn btn-primary mt-2" @click.prevent="submitPrices" :disabled="!buttonEnable">
    </div>
  </div>
</template>

<script>

export default {
  name: "fill-leasing-prices",
  props: {},
  data() {
    return {
      textarea: '',
      error: false,
      errorMessage: ''
    }
  },
  computed: {
    isValid() {
      return !this.error;
    },
    prices() {
      try {
        this.error = false
        let prices = []
        let lines = this.textarea.split(/\r?\n/);
        lines = _.reject(lines, _.isEmpty)
        lines = _.map(lines, _.trim)
        if (!lines.length) {
          return null
        }
        if (lines.length < 2) {
          throw "Keine Leasingraten erkannt (weniger als 2 Zeilen)"
        }
        let headerColumns = lines[0].split(/\s+/);
        if (headerColumns.length < 2) {
          throw "Monate in der Kopfzeile nicht erkannt (weniger als 2 Spalten)"
        }
        headerColumns = headerColumns.slice(-(headerColumns.length - 1));
        lines = lines.slice(-(lines.length - 1));
        lines.forEach(function (priceLine, index) {
          let priceColumns = priceLine.split(/\s+/)
          let rates = priceColumns.slice(-(headerColumns.length))
          if (priceColumns.length !== (headerColumns.length + 2)) {
            throw `Spalten mit Leasingraten passen nicht zu den Monaten (Zeile ${index + 2})`
          }
          let milage = parseInt(priceColumns[0].replace(/[.,]\d\d?$/, '').replace(/\D/, ''))
          if (!_.isFinite(milage) || milage === 0) {
            throw `Fahrleistung (in km/Jahr) muss eine Zahl größer 0 sein (Zeile ${index + 2})`
          }
          headerColumns.forEach(function (month, monthIndex) {
            month = parseInt(month)
            if (!_.isFinite(month) || month === 0) {
              throw `Monat in der Kopfzeile muss Zahl größer 0 sein (Spalte ${monthIndex + 2})`
            }
            let tempRate = rates[monthIndex].replace(/[.,]\d\d?$/, '').replace(/\D/, '')
            if (rates[monthIndex].match(/[.,]\d\d?$/)) {
              tempRate = tempRate + rates[monthIndex].replace(/^.+[.,](\d\d?)$/, '.$1')
            }
            tempRate = parseFloat(tempRate)
            if (_.isFinite(tempRate) && tempRate !== 0) {
              prices.push({
                duration_months: month,
                mileage_per_year: milage,
                leasing_rate: tempRate
              })
            }
          });
        }, this);
        return prices
      } catch (e) {
        this.errorMessage = e
        this.error = true
        return null
      }
    },
    buttonEnable () {
      return !!(this.isValid && this.prices?.length);
    }
  },
  watch: {
    textarea: {
      handler() {
        if (this.error) { // when adding the data incorrectly the error message is displayed but the reference if the prices() is not recalled so the component will not be updated
          // this will fix the issue of calling the reference every time there is an error and the textarea is updated
          return this.prices
        }
      }
    }
  },

  methods: {
    submitPrices() {
      this.$emit('updateleasingprices', this.prices)
    }

  }
}
</script>

<style scoped lang="scss">
.image {
  display: block;
  max-width: 100%;
}
</style>
