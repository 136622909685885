<template>
    <div v-bind:class=root_classes>
        <div class="btn-group-toggle" v-bind:class=group_classes>
            <label ref="btnLabel" class="btn" v-bind:class=label_classes>

                <input v-if="!disabled" ref="cb" type="checkbox" :name="name" autocomplete="off" value="1"
                       v-model="active"/>
                <input ref="hidden_cb" type="hidden" value="0" :name="name">
                <input v-if="disabled" type="checkbox" value="0" disabled="disabled">

                <span v-if="icon_classes" class="fas" v-bind:class=icon_classes></span>
                <span v-else-if="toggle_icon_classes" class="fas" v-bind:class="toggle_icon_classes[active ? 0 : 1]"></span>
                <img v-else-if="custom_icon_src" v-bind:class=custom_icon_classes :src="custom_icon_src"/>
                <span v-else>{{label}}</span>
            </label>
        </div>
    </div>
</template>

<script>
/**
 *
 * !!! TODO: REPLACE this component !!!
 *
 */
  export default {
    name: "custom-btn-checkbox",
    props: {
      name: {type: String},
      root_classes: {type: String},
      group_classes: {type: String},
      label_classes: {type: String},
      icon_classes: {type: String},
      toggle_icon_classes: {type: Array, length: 2},
      custom_icon_src: {type: String},
      custom_icon_classes: {type: String},
      label: {type: String, default: '### NO LABEL YET ###'},
      disabled: {type: Boolean, default: false},
      value: {},
    },
    data() {
      return {
        active: this.getActiveFromValue(this.value)
      };
    },
    mounted() {
      this.checkVisualState();
    },
    computed: {},
    watch: {

      value(value) {
        //console.log('CB Value changed: ', value);
        this.active = this.getActiveFromValue(value);
      },

      active: {
        handler: function () {
          //console.log('CB Active changed: ', this.active);

          // auto change active class
          this.checkVisualState();

          // emit changes on active to parent(s)
          this.$emit('input', this.active)
        },
        deep: true
      }
    },
    methods: {

      checkVisualState() {

        if ( this.disabled ) {
          this.$refs.btnLabel.classList.add('disabled');
          return;
        }
        else {
          this.$refs.btnLabel.classList.remove('disabled');
        }

        // console.log('checkVisualState: '+this.label);
        if (this.active === true) {
          this.$refs.btnLabel.classList.add('active');
          this.$refs.hidden_cb.disabled = true;

          if (this.$el.attributes['data-toggle'] && this.$el.attributes['data-toggle'].value === 'collapse' && this.$el.attributes['data-target']) {
            $(this.$el.attributes['data-target'].value).collapse('show');
          }

        } else {
          this.$refs.btnLabel.classList.remove('active');
          this.$refs.hidden_cb.disabled = false;

          if (this.$el.attributes['data-toggle'] && this.$el.attributes['data-toggle'].value === 'collapse' && this.$el.attributes['data-target']) {
            $(this.$el.attributes['data-target'].value).collapse('hide');
          }
        }
      },

      getActiveFromValue(value) {
        return (value === 'true' || value === true || value === '1' || value === 1);
      }
    }
  }
</script>
