<script>
import customVueSelect from '../components/custom-vue-select'
import customPrependSelect from '../components/custom-prepend-select'
import customVueSlider from '../components/custom-vue-slider'
import customBtnCheckbox from '../components/custom-btn-checkbox'
import customBtnRadioGroup from '../components/custom-btn-radio-group'
import dateTimeInput from '../components/date-time-input'
import leasingPriceForm from '../components/leasing-price-form'
import fillLeasingPrices from '../components/fill-leasing-prices'
import offerHighlightsForm from '../components/offer-highlights-form'
import offerImageForm from '../components/offer-image-form'
import offerAttachmentForm from '../components/offer-attachment-form'
import carEquipmentsForm from '../components/car-equipments-form'
import numericInput from '../components/numeric-input'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {t_scoped} from '../i18n/i18n'
import ModalControl from "../packs/modal-control";

const t_offer_edit_form = t_scoped('offer.edit.form');

export default {
  name: "offer-edit-form",
  template: '#Vue-OfferEditForm',
  components: {
    customVueSelect,
    customPrependSelect,
    customVueSlider,
    customBtnCheckbox,
    customBtnRadioGroup,
    dateTimeInput,
    leasingPriceForm,
    offerHighlightsForm,
    offerImageForm,
    offerAttachmentForm,
    carEquipmentsForm,
    fillLeasingPrices,
    numericInput
  },
  props: {
    offer: {type: String},
    car: {type: String},
    car_energy_consumption: {type: String},
    make: {type: String},
    model: {type: String},
    model_url: {type: String},
    p_models: {type: Array},
    p_leasing: {type: String},
    p_leasing_prices: {type: Array},
    p_highlights: {type: Array},
    fuels: {type: Array},
    errors: {type: Object, required: true}
  },
  data() {
    return {
      jOffer: JSON.parse(this.offer),
      jCar: JSON.parse(this.car),
      jCarEnergyConsumption: JSON.parse(this.car_energy_consumption),
      jModel: JSON.parse(this.model),
      jLeasing: JSON.parse(this.p_leasing),
      models: this.p_models,
      leasing_prices: this.p_leasing_prices,
      highlights: this.p_highlights,
      imageUploadsRunning: false,
      attachmentUploadsRunning: false,
    }
  },
  computed: {

    leasingCanHavePremium() {
      if (!this.jCar.fuel_id || typeof (this.jCar.fuel_id) === 'undefined') {
        this.jLeasing.has_premium = null;
        this.jLeasing.premium_value = null;
        return false;
      }

      let currentFuel = this.fuels.find((fuel) => {
        return parseInt(fuel.id) === parseInt(this.jCar.fuel_id);
      });
      if (currentFuel &&
          typeof (currentFuel) !== 'undefined' &&
          ['ELECTRICITY', 'HYBRID', 'HYBRID_ALL', 'HYBRID_DIESEL'].includes(currentFuel.key)) {
        return true;
      } else {
        this.jLeasing.has_premium = null;
        this.jLeasing.premium_value = null;
        return false;
      }
    },

  },
  watch: {
    'jCar.make_id': {
      // immediate: true,
      handler: function (make_id) {
        if (this.model_url && make_id != null) {
          const request = $.get(this.model_url, {make: make_id});
          return request.done((result) => {
            this.models = result.models;
          });
        } else {
          this.models = [];
        }
      }
    }
  },
  methods: {
    t_offer_edit_form,

    validateSubmit() {

      // check image & attachment uploads
      if (this.imageUploadsRunning || this.attachmentUploadsRunning) {
        return false;
      }

      // check leasing prices (leasing_rate is set, uniqueness of mileage_per_year & duration_months)
      let valid = this.validateLeasingPrices();



      if (valid) {
        let editForm = document.getElementById('form-offer-edit');
        editForm.submit();
      }
      else {
        return false;
      }
    },

    validateLeasingPrices() {
      let uniqPropsMap = _.map(this.leasing_prices, (lp)=>{return lp.mileage_per_year + lp.duration_months;}),
          valid = true,
          lpIndex = 0;

      this.leasing_prices.forEach((leasingPrice)=>{

        // CHECK 1/2: Check leasing rate is set
        if ( _.isNil(leasingPrice.leasing_rate) || leasingPrice.leasing_rate === '') {
          let leasingRateErrElement = document.getElementById('leasing_rate_error_'+lpIndex),
              leasingRateErrMsgElement = document.getElementById('leasing_rate_error_msg_'+lpIndex);

          leasingRateErrElement.classList.add('field_with_errors');
          leasingRateErrMsgElement.innerHTML = t_offer_edit_form('error.must_exist');

          valid = false;
          return false;
        }

        // CHECK 2/2: Check uniqueness of duration_months & mileage_per_year
        let mapIndex = 0;
        uniqPropsMap.forEach((hash)=>{

          if ( hash === (leasingPrice.mileage_per_year + leasingPrice.duration_months) &&
              lpIndex !== mapIndex )
          {
            let durationMonthsErrElement = document.getElementById('duration_months_error_'+lpIndex),
                durationMonthsErrMsgElement = document.getElementById('duration_months_error_msg_'+lpIndex),
                mileagePerYearErrElement = document.getElementById('mileage_per_year_error_'+lpIndex),
                mileagePerYearErrMsgElement = document.getElementById('mileage_per_year_error_msg_'+lpIndex);

            durationMonthsErrElement.classList.add('field_with_errors');
            durationMonthsErrMsgElement.innerHTML = t_offer_edit_form('error.must_be_unique');

            mileagePerYearErrElement.classList.add('field_with_errors');
            mileagePerYearErrMsgElement.innerHTML = t_offer_edit_form('error.must_be_unique');

            valid = false;
          }

          mapIndex++;
        });

        lpIndex++;
      });

      return valid;
    },

    simplifyMake(make) {
      return {
        value: make.id,
        label: make.key_t
      };
    },

    onLeasingPricesChanged(leasingPrices) {
      this.leasing_prices = leasingPrices;
    },

    onHighlightsChanged(highlights) {
      this.highlights = highlights;
    },

    hasPaneErrors(pane) {
      let newError  = false,
          re        = new RegExp(pane, "m"),
          errorKeys = Object.keys(this.errors);

      if (pane !== 'offer') {
        errorKeys.forEach(function (key) {
          if (key.search(re) >= 0) {
            newError = true;
            return true;
          }
        });
      } else {
        newError = this.errors.hasOwnProperty('service_fee') || this.errors.hasOwnProperty('title');
      }

      return newError;
    },

    onImageUploadsRunning(running) {
      this.imageUploadsRunning = running;
    },

    onAttachmentUploadsRunning(running) {
      this.attachmentUploadsRunning = running;
    },

    matchingFunction(priceRate) {
      return function (e) {
        return (e.duration_months === priceRate.duration_months &&
            e.mileage_per_year === priceRate.mileage_per_year);
      }
    },

    updateLeasingPrices(prices) {
      let clonedLeasingPrices = _.cloneDeep(this.leasing_prices)
      clonedLeasingPrices.forEach(function (priceRate, index) {
        if (!prices.filter(this.matchingFunction(priceRate)).length > 0) {
          clonedLeasingPrices[index].deleted = true
        }
      }, this);

      prices.forEach(function (priceRate, index) {
        let matchingIndex = clonedLeasingPrices.findIndex(this.matchingFunction(priceRate));
        if (matchingIndex >= 0) {
          clonedLeasingPrices[matchingIndex].leasing_rate = priceRate.leasing_rate
        } else {
          clonedLeasingPrices.push({...priceRate, initial_payments: [{initial_payment: 0, best_price: false, monthly_payment: 0}], key: `KEY_${Math.round(Math.random() * 1000000000)}`})
        }

      }, this);
      clonedLeasingPrices = _.sortBy(clonedLeasingPrices, ['duration_months', 'mileage_per_year']);
      this.onLeasingPricesChanged(clonedLeasingPrices)
      ModalControl.show(
          'modal-simple-confirm',
          {
            title: 'Preise wurden ersetzt',
            question: 'Bitte alle Preise überprüfen! Nach dem Überprüfen muss das Angebot gespeichert werden, damit die neuen Preise sichtbar werden.',
            buttons: [
              ModalControl.BUTTON_CLOSE
            ]
          }
      );
    }
  }
}
</script>
