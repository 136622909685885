/*
  Frontend version of text_format helper.

  @see app/helpers/text_format_helper.rb
 */

import _ from 'lodash';

const INTERPOLATION_REGEX = /[$%]{(\w+)}/g;

function interpolate(value, interpolations) {
  if(!_.isString(value)) {
    return value;
  }
  interpolations = interpolations || {};
  return value.replace(INTERPOLATION_REGEX, (_, key) => {
    return interpolations[key];
  });
}

function locale() {
  return window.LOCALE;
}

function t(key, interpolations, format) {
  const value = _.get(window.TRANSLATIONS, key);
  if(value) {

    const text = interpolate(value, interpolations);

    if (typeof format === 'undefined' || format === 'html') {
      let mapping = {
        'b': '<strong>%s</strong>',
        'i': '<em>%s</em>',
        'u': '<u>%s</u>',
        's': '<s>%s</s>',
        'br': '<br>'
      };
      return reformat(text, mapping);

    } else if (format === 'text') {
      let mapping = {
        'b': '%s',
        'i': '%s',
        'u': '%s',
        's': '%s',
        'br': '\n'
      };
      return reformat(text, mapping);

    } else {
      return text
    }
  } else {
    return `js translation missing: ${key}`
  }
}

function reformat(text, format_mapping) {
  if (!_.isString(text)) {
    return text;
  }

  // STEP 1: format linebreaks
  text = text.replace(/\r?\n/g, format_mapping['br']);

  // STEP 2: formatting
  let iterations = 0,
      last_result = '';
  while ( last_result !== text && iterations < 10 ) {
    last_result = text;
    text = text.replace(/\[([a-z]+)\](.*?)\[\/\1\]/g, (match, p1, p2, offset, string) => {

      if (!p1.length || !format_mapping.hasOwnProperty(p1) ) {
        // nil/false mappings erase the whole match
        return ''
      }

      return format_mapping[p1].replace(/%s/g, p2);
    });

    iterations++;
  }

  return text;
}

function t_scoped(scope) {
  return (key, interpolations) => t(`${scope}.${key}`, interpolations);
}

export {t, t_scoped, locale};
