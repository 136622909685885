<template>
  <div class="custom-checkbox-btn">
    <div class="btn-group-toggle">
      <label :for="'custom-cb-btn-'+name" class="btn" :class="computedBtnClasses"
      >
        <input :id="'custom-cb-btn-'+name"
               type="checkbox"
               :checked="isActive"
               :name="name"
               :value="value"
               @click="clicked"
        >
        <i :class="computedCbClasses"></i>
      </label>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: "custom-checkbox-btn",
  props: {
    name: {type: String, required: true},
    value: {type: Boolean, default: false},
    btnClasses: {type: Array, default: ()=>{return []}},
    cbActiveClasses: {type: Array, default: ()=>{return ['fas fa-check']}},
    cbInactiveClasses: {type: Array, default: ()=>{return ['fas fa-square invisible']}}
  },

  data() {
    return {
    }
  },

  computed: {
    computedBtnClasses() {
      return this.isActive ? ['active'].concat(this.btnClasses).join(' ') : this.btnClasses.join(' ')
    },
    computedCbClasses() {
      return this.isActive ? this.cbActiveClasses.join(' ') : this.cbInactiveClasses.join(' ')
    },
    isActive() {
      return _.isNil(this.value) ? false : this.value;
    },
  },

  methods: {
    clicked() {
      this.$emit('input', _.isNil(this.value) ? true : !this.value);
    },
  },
}
</script>
