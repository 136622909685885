<template>
    <div @click="onClick">
        ICH BIN FOO {{count}}
    </div>
</template>

<script>
    export default {
        props: {
            count: {type: Number, required: true}
        },
        data() {
            return {
                foo: this.count
            }
        },
        methods: {
            onClick() {
                this.$emit('mein-event', this.count);
            }
        }

    }
</script>
