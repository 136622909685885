<script>
  import customVueSelect from '../components/custom-vue-select';
  import ModalControl from '../packs/modal-control';
  import _ from 'lodash';
  import {t_scoped} from '../i18n/i18n'

  const t_offer_price = t_scoped('offer_price_selector');

  function to_vue_select_options(options, field) {
    // TODO FIXME XXX es werden nur irgendein gross und net aus der originalen option gebraucht, lieber explizit benennen anstelle großflächig zu clonen
    // console.log(field, options)
    const bla = options.map(function (option) {
      let o2 = _.clone(option)
      o2['label'] = option[field + '_label']
      o2['value'] = option[field]
      // console.log('option', o2)
      return o2
    })
    return bla
  }

  export default {
    name: "offer-price-selector",
    components: {customVueSelect},
    props: {
      leasing_prices: {type: Array, required: true},
      generic_duration_months: {type: Array, required: true},
      generic_mileages_per_year: {type: Array, required: true},
      generic_initial_payments: {type: Array, required: true},
      selected_leasing_price_id: {type: Number},
      selected_initial_payment_gross: {type: Number},
      leasing_offer_request: {type: Object, required: true},
      multiple_gross_prices: {type: Boolean, default: false},
      default_vat_factor: {type: Number, required: true},
    },
    data() {
      let initial_leasing_price = this.selected_leasing_price_id && _(this.leasing_prices).find({'id': this.selected_leasing_price_id}) || this.leasing_prices[0];
      let initial_initial_payment = _.isNumber(this.selected_initial_payment_gross) && _(initial_leasing_price.initial_payments).find({initial_payment: this.selected_initial_payment_gross}) || initial_leasing_price.initial_payments[0];
      return {
        selected_duration_months: initial_leasing_price.duration_months,
        selected_mileage_per_year: initial_leasing_price.mileage_per_year,
        selected_initial_payment: initial_initial_payment.initial_payment
      }
    },
    computed: {
      duration_months_options() {
        const options = _(this.leasing_prices)
          .uniqBy('duration_months')
          .sortBy('duration_months')
          .value();
        return to_vue_select_options(options, 'duration_months');
      },
      mileage_per_year_options() {
        if (this.selected_duration_months) {
          const options = _(this.leasing_prices)
            .filter({duration_months: this.selected_duration_months})
            .uniqBy('mileage_per_year')
            .sortBy('mileage_per_year')
            .value();
          return to_vue_select_options(options, 'mileage_per_year');
        } else {
          return [];
        }
      },
      initial_payment_options() {
        if (this.selected_duration_months && this.selected_mileage_per_year) {
          const leasing_price = _(this.leasing_prices)
            .find({
              duration_months: this.selected_duration_months,
              mileage_per_year: this.selected_mileage_per_year
            });
          return leasing_price && to_vue_select_options(leasing_price.initial_payments, 'initial_payment') || [];
        } else {
          return [];
        }
      },
      selected_monthly_payment() {
        if (this.selected_duration_months && this.selected_mileage_per_year && _.isNumber(this.selected_initial_payment)) {
          const leasing_price = _(this.leasing_prices)
            .find({
              duration_months: this.selected_duration_months,
              mileage_per_year: this.selected_mileage_per_year,
            });
          if (!leasing_price) {
            return null;
          }
          return _(leasing_price.initial_payments)
            .find({initial_payment: this.selected_initial_payment})
        }
      },
      monthly_payment_gross_label() {
        console.log('selected_monthly_payment: ', this.selected_monthly_payment);
        if (!this.selected_monthly_payment) {
          return null;
        }

        if (this.multiple_gross_prices) {
          return this.selected_monthly_payment.monthly_payment_gross_label;
        }
        else {
          return this.selected_monthly_payment.monthly_payment_gross_label[0];
        }

        // return this.selected_monthly_payment && this.selected_monthly_payment.monthly_payment_gross_label
      },
      monthly_payment_net_label() {
        return this.selected_monthly_payment && this.selected_monthly_payment.monthly_payment_net_label
      }
    },
    watch: {},
    methods: {
      t_offer_price,
      open_request_modal() {
        ModalControl.show(
          'modal-offer-request',
          {
            no_button: true,
            modal_classes: ['no-seperator', 'pb-0'],
            content_classes: [],
            dialog_classes: ['w-65p'],
            header_classes: ['no-seperator', 'pb-0'],
            footer_classes: ['no-seperator', 'mx-3', 'flex-wrap'],
            leasing_prices: this.leasing_prices,
            generic_duration_months: this.generic_duration_months,
            generic_mileages_per_year: this.generic_mileages_per_year,
            generic_initial_payments: this.generic_initial_payments,
            select_duration_months: this.selected_duration_months,
            select_mileage_per_year: this.selected_mileage_per_year,
            select_initial_payment: this.selected_initial_payment,
            p_leasing_offer_request: this.leasing_offer_request
          }
        );

      }

    }
  }
</script>

<style scoped>

</style>