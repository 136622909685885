<template>
  <div class="custom-radio-btn-group" :class="computedRootsClasses">

<!--    <pre wrap>{{ options }}</pre>-->

    <div class="btn-group btn-group-toggle flex-wrap" :class="computedGroupClasses" role="group">
      <label class="btn"
             :class="computedBtnClasses(option)"
             v-for="option in options"
      >
        <input type="radio"
               :key="option.id"
               :name="name"
               :checked="isActive(option)"
               :value= "option.id"
               @click="clicked(option.id)"
        >
        {{option.label}}
      </label>
    </div>

  </div>
</template>

<script>
export default {
  name: "custom-radio-btn-group",
  props: {
    name: {type: String, required: true},
    options: {type: Array, default: []},
    value: null, // can be any type STRING, NUMBER, etc.
    rootClasses: {type: Array, default: ()=>{return []}},
    groupClasses: {type: Array, default: ()=>{return []}},
    btnClasses: {type: Array, default: ()=>{return []}},
  },

  data() {
    return {
    }
  },

  computed: {
    computedRootsClasses(){
      return this.rootClasses.join(' ');
    },
    computedGroupClasses(){
      return this.groupClasses.join(' ');
    },
  },

  methods: {
    computedBtnClasses(option){
      return this.isActive(option) ? ['active'].concat(this.btnClasses).join(' ') : this.btnClasses.join(' ');
    },
    isActive(option){
      let radio_value = isNaN(this.value) ? this.value : parseInt(this.value);

      return option.id === radio_value;
    },

    clicked(fuelId){
      this.$emit('input', fuelId === this.value ? null : fuelId);
    }
  }
}
</script>
