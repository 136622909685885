<template>
    <!--
        https://vuejs.org/v2/api/#keep-alive
    -->
    <keep-alive include="[]">
        <div>
            <template v-for="({component, props, key}, i) in stack">
                <Component :is='component' v-show="i === stack.length - 1" v-bind="props" :key="key" @close="onClose"/>
            </template>
        </div>
    </keep-alive>
</template>

<script>
  import Modal from './modal';
  import ModalLogin from './modal-login';
  import ModalMessage from './modal-message';
  import ModalSlider from './modal-slider';
  import ModalSimpleConfirm from './modal-simple-confirm';
  import ModalSimpleSelect from './modal-simple-select';
  import ModalOfferRequest from './modal-offer-request'

  import ModalControl from '../../packs/modal-control';

  export default {
    components: {
        Modal,
        ModalLogin,
        ModalMessage,
        ModalSlider,
        ModalSimpleConfirm,
        ModalSimpleSelect,
        ModalOfferRequest
    },
    data() {
      return {
        stack: [],
      }
    },
    computed: {},
    methods: {
      onClose(payload) {
        ModalControl.hide(payload);
      }
    },
    created() {
      //console.log('Created!');
      ModalControl.onChange(stack => {
        this.stack = stack;
      });
    }
  };
</script>
