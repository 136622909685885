<script>
  import customVueSelect from '../components/custom-vue-select'
  import customBtnCheckbox from '../components/custom-btn-checkbox'
  import customBtnRadioGroup from '../components/custom-btn-radio-group'
  import dateTimeInput from '../components/date-time-input'
  import axios from 'axios'
  import VueAxios from 'vue-axios'

  export default {
    template: '#Vue-LeasingOfferRequestFilterForm',
    components: {
      customVueSelect,
      customBtnCheckbox,
      customBtnRadioGroup,
      dateTimeInput
    },
    props: {
      search_params: {type: String},
      count_url: {type: String}
    },
    data() {
      return {
        count: null,
        j_search_params: JSON.parse(this.search_params)
      }
    },
    computed: {
    },
    watch: {
      j_search_params: {
        immediate: true,
        handler: function (search_params) {
          if (this.count_url) {
            const request = $.get(this.count_url, search_params);
            return request.done((result) => {
              this.count = result.count
            });
          }
        },
        deep: true
      }
    },
    methods: {
      test() {
        console.log('TEST!', this.count);
      }
    }
  }
</script>
