/**
 * AXIOS based API
 * @see https://github.com/axios/axios
 *      https://github.com/axios/axios#request-config
 *
 * Primary used to provide VUEX store(s) with an ajax based API.
 *
 * Check if needed:
 * - request.abort() action: @see https://github.com/axios/axios#cancellation
 * - request.before() action: @see https://github.com/axios/axios#interceptors
 */
import axios from 'axios';

axios.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0]
  .getAttribute('content');
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.timeout = 30000;

export default {

  // needed when request should be cancelable
  newCancelableRequest() {
    return axios.CancelToken.source();
  },

  // API aliases
  get(url, request) {
    return axios.get(url, request)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  post(url, request) {
    return axios.post(url, request)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  patch(url, request) {
    return axios.patch(url, request)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  put(url, request) {
    return axios.put(url, request)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  delete(url, request) {
    return axios.delete(url, request)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  // could be called directly but is provided here for be completion reasons
  cancel(request, msg) {
    request.cancel(msg);
  },
}