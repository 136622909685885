<template>
  <div class="modal Modal" v-bind:class=modal_classes @click.self="close()">
      <div class="Modal-message d-flex justify-content-center" v-bind:class=message_classes>
          <div class="align-self-center" v-html="message"></div>
      </div>
  </div>
</template>

<script>
  export default {
    props: {
      message:            {type: String},
      modal_classes:      {type: Array},
      message_classes:    {type: String},
      on_close_callback:  {type: Function},
      close_after:        {type: Number, required: false}
    },
    data() {
        return {
          close_timeout: this.close_after ? window.setTimeout(this.close, this.close_after) : null
      }
    },
    computed: {},
    mounted(){
    },
    methods: {
      close(payload) {
        if(this.close_timeout){

          window.clearTimeout(this.close_timeout);
        }
        if ( this.on_close_callback ) {
          this.on_close_callback();
        }
        this.$emit('close', payload);
      },
      onClick(callback) {
        callback(this.close);
      }
    }
  }
</script>
