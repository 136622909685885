<script>
  import customVueSelect from '../components/custom-vue-select';
  import {SlickList, SlickItem, HandleDirective} from 'vue-slicksort';

  export default {
    directives: {handle: HandleDirective},
    name: "offer-highlights-form",
    components: {customVueSelect, SlickList, SlickItem},
    props: {
      highlights: {type: Array},
      blueprint: {type: Object},
      errors: {type: Object}
    },
    data() {
      return {
        jHighlights: JSON.stringify(this.highlights)
      }
    },
    computed: {
      highlights_sort() {
        return _.cloneDeep(this.highlights);
      }
    },
    watch: {},
    methods: {

      correctNameIndex(name, index) {
        return name.replace(/\[\d+\]/, "[".concat(index, ']'));
      },

      correctIdIndex(id, index) {
        return id.replace(/_\d+_/, "_".concat(index, '_'));
      },

      addHighlight() {
        let clonedHighlights = _.cloneDeep(this.highlights);
        const blueprint = _.cloneDeep(this.blueprint);
        blueprint.key = `KEY_${Math.round(Math.random() * 1000000000)}_${Date.now()}`;
        blueprint.order = this.getVisibleHighlightsCnt();
        clonedHighlights.push(blueprint);

        this.$emit('highlightsChanged', clonedHighlights);
      },

      removeHighlight(index) {
        let clonedHighlights = _.cloneDeep(this.highlights);

        if (clonedHighlights[index].id) {
          clonedHighlights[index].deleted = true;
        } else {
          clonedHighlights.splice(index, 1);
        }

        clonedHighlights = this.manualSetHighlightsOrder(clonedHighlights);

        this.$emit('highlightsChanged', clonedHighlights);
      },

      manualSetHighlightsOrder(highlightsArr) {
        let orderCnt = 0;

        for (var i = 0; i < highlightsArr.length; i++) {
          // console.log('ITEM:', highlightsArr[i], highlightsArr[i].hasOwnProperty('deleted'), i, orderCnt);
          if (!highlightsArr[i].hasOwnProperty('deleted')) {
            highlightsArr[i].order = orderCnt;
            orderCnt++;
          }
        }
        return highlightsArr;
      },

      getVisibleHighlightsCnt() {
        let orderCnt = 0;
        for (var i = 0; i < this.highlights.length; i++) {
          if (!this.highlights[i].hasOwnProperty('deleted')) {
            orderCnt++;
          }
        }
        return orderCnt;
      },

      highlightsSorted(newOrderArr) {

        let clonedHighlights = _.cloneDeep(this.highlights),
            newOrderLength   = newOrderArr.length,
            clonedLength     = clonedHighlights.length,
            orderCnt         = 0,
            newOrderItem     = null;

        // set order property by position in order array
        for (var i = 0; i < newOrderLength; i++) {
          newOrderItem = newOrderArr[i];

          if (newOrderItem.hasOwnProperty('deleted')) {
            continue;
          }

          for (var j = 0; j < clonedLength; j++) {
            if (!clonedHighlights[j].hasOwnProperty('deleted') &&
              (clonedHighlights[j].hasOwnProperty('id') && newOrderItem.hasOwnProperty('id')) &&
              (clonedHighlights[j].id === newOrderItem.id) ||
              (clonedHighlights[j].hasOwnProperty('key') && newOrderItem.hasOwnProperty('key')) &&
              (clonedHighlights[j].key === newOrderItem.key))
            {
              clonedHighlights[j].order = orderCnt;
              orderCnt++;
            }
          }
        }

        // sort cloned highlights to be in correct order in array, to be displayed correctly
        clonedHighlights.sort(function (a, b) {
          var keyA = a.order,
              keyB = b.order;
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });

        this.$emit('highlightsChanged', clonedHighlights);
      },

      errorClassFor(attrName, index){
        return {'field_with_errors': this.hasErrors(attrName, index)}
      },
      errorMessageFor(attrName, index){
        return (this.errors[`highlights[${index}].${attrName}`] || []).join(", ");
      },
      hasErrors(attrName, index){
        // console.log('hasErrors ALL:', this.errors, this.errors[`highlights[${index}].${attrName}`]);
        // console.log('hasErrors INDEX, ATTR:', index, attrName);
        // console.log('hasErrors TARGET:', this.errors[`highlights[${index}].${attrName}`]);
        return this.errors[`highlights[${index}].${attrName}`];
      }
    }
  }
</script>
