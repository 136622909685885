<script>
    import Modal from './modal';
    import ModalControl from '../../packs/modal-control';
    import axios from 'axios'
    import VueAxios from 'vue-axios'
    import CustomRadioBtnGroup from '../custom-radio-btn-group'
    import CustomVueSelect from '../custom-vue-select'
    import CustomBtnCheckbox from '../custom-btn-checkbox'
    import Vue from 'vue'
    import _ from 'lodash';

    function to_vue_select_options(options, field) {
        // console.log('to_vue_select_options', field, options)
        return options.map(o => (_.merge({
            label: o[field + '_label'],
            value: o[field]
        }, o)))
    }

    export default {
        template: '#Vue-ModalOfferRequest',
        components: {Modal, CustomRadioBtnGroup, CustomVueSelect, CustomBtnCheckbox},
        props: {
            no_button: {type: Boolean, default: false},
            modal_classes: {type: Array},
            content_classes: {type: Array},
            dialog_classes: {type: Array},
            header_classes: {type: Array},
            body_classes: {type: Array},
            footer_classes: {type: Array},
            leasing_prices: {type: Array, required: true},
            generic_duration_months: {type: Array, required: true},
            generic_mileages_per_year: {type: Array, required: true},
            generic_initial_payments: {type: Array, required: true},
            select_duration_months: {type: Number},
            select_mileage_per_year: {type: Number},
            select_initial_payment: {type: Number},
            p_leasing_offer_request: {type: Object, required: true}
        },
        data() {
            const leasing_offer_request = _.cloneDeep(this.p_leasing_offer_request);

            // ATTENTION! privacy_terms_accepted needs to be initialized or it won't show up or be set by form
            // automatically since it is a virtual attribute of LeasingOfferRequest model
            leasing_offer_request.privacy_terms_accepted = 0;

            if (this.select_duration_months) {
                leasing_offer_request.duration_months = this.select_duration_months;
            }
            if (this.select_mileage_per_year) {
                leasing_offer_request.mileage_per_year = this.select_mileage_per_year;
            }
            if (_.isNumber(this.select_initial_payment)) {
                leasing_offer_request.initial_payment = this.select_initial_payment;
            }
            return {
                leasing_offer_request: leasing_offer_request,
                request: {
                    running: false,
                    exception: false
                },
                errors: {}
            }
        },
        computed: {
            duration_months_options() {
                // determine unique duration month values and create options from them
                const leasing_prices = _(this.leasing_prices)
                    .uniqBy('duration_months')
                    .value();
                let options = to_vue_select_options(leasing_prices, 'duration_months');
                // concat generic durations, uniq the resulting array (first entries survive), sort and return result.
                return _(options)
                    .concat(this.generic_duration_months)
                    .uniqBy('value')
                    .sortBy('value')
                    .value();
            },
            mileage_per_year_options() {
                if (this.leasing_offer_request.duration_months) {
                    const leasing_prices = _(this.leasing_prices)
                        .filter({'duration_months': this.leasing_offer_request.duration_months})
                        .uniqBy('mileage_per_year')
                        .value();
                    let options = to_vue_select_options(leasing_prices, 'mileage_per_year');
                    return _(options)
                        .concat(this.generic_mileages_per_year)
                        .uniqBy('value')
                        .sortBy('value')
                        .value();
                } else {
                    return this.generic_mileages_per_year
                }
            },
            initial_payment_options() {
                if (this.leasing_offer_request.duration_months && this.leasing_offer_request.mileage_per_year) {
                    if (!this.selected_leasing_price) {
                        return this.generic_initial_payments
                    }
                    return to_vue_select_options(this.selected_leasing_price.initial_payments, 'initial_payment')
                } else {
                    return []
                }
            },
            selected_leasing_price() {
                if (this.leasing_offer_request.duration_months && this.leasing_offer_request.mileage_per_year) {
                    return _(this.leasing_prices)
                        .find({
                            'duration_months': this.leasing_offer_request.duration_months,
                            'mileage_per_year': this.leasing_offer_request.mileage_per_year,
                        });
                }
            },
        },
        // beforeMount() {
        //   this.errorState = { errors: [] };
        // },
        methods: {
            selected_initial_payment_object() {
                // console.log('this.leasing_offer_request.initial_payment', this.leasing_offer_request.initial_payment)
                if (_.isNumber(this.leasing_offer_request.initial_payment)) {
                    // console.log('this.initial_payment_options', this.initial_payment_options)
                    return _(this.initial_payment_options)
                        .find({value: this.leasing_offer_request.initial_payment})
                }
            },
            close() {
                this.$emit('close');
            },
            async submitForm() {
                if (this.request.running) {
                  return
                }
                // console.log(this.leasing_offer_request)
                this.request.running = true;
                this.request.exception = false;
                try {
                    const sipo = this.selected_initial_payment_object()
                    // console.log('sipo', sipo)
                    if (sipo) {
                        this.leasing_offer_request.initial_payment_gross = sipo.initial_payment_gross
                        this.leasing_offer_request.initial_payment_net = sipo.initial_payment_net
                    } else {
                        this.leasing_offer_request.initial_payment =
                            this.leasing_offer_request.initial_payment_gross =
                                this.leasing_offer_request.initial_payment_net =
                                    null
                    }
                    // console.log('this.leasing_offer_request', this.leasing_offer_request)
                    const result = await this.$http
                        .post(
                            '/leasing_offer_requests',
                            {
                                leasing_offer_request: this.leasing_offer_request
                            }
                        );
                    if (result.data.error) {
                        this.errors = result.data.errors
                    } else {
                        ModalControl.hide();
                        window.location.href = result.data.redirect;
                    }

                } catch (exception) {
                    Vue.rollbar.error(exception);
                    this.request.exception = true;

                } finally {
                    this.request.running = false;
                }
            },
            hasError(fieldName) {
                const hasError = Array.isArray(this.errors[fieldName]) && this.errors[fieldName].length > 0;
                return hasError;
            },
            errorMessage(fieldName) {
                return Array.isArray(this.errors[fieldName]) && this.errors[fieldName].length > 0 ? this.errors[fieldName][0] : '';
            }
        }
    }
</script>
