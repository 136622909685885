<script>
  import _ from 'lodash';
  import CustomBtnCheckbox from "../components/custom-btn-checkbox";
  import CustomVueSelect from "../components/custom-vue-select";
  import Uploader from "../utils/uploader";

  export default {
    components: {CustomBtnCheckbox, CustomVueSelect},
    props: {
      pImages: {type: Array, required: true},
      blueprint: {type: Object, required: true}
    },
    data() {
      return {
        images: _.cloneDeep(this.pImages),
      };
    },
    computed: {
      requestsRunning() {
        return this.images.some(i => i.uploader && i.uploader.running);
      }
    },
    methods: {
      correctNameIndex(name, index) {
        return name.replace(/\[\d+\]/, "[".concat(index, ']'));
      },

      correctIdIndex(id, index) {
        return id.replace(/_\d+_/, "_".concat(index, '_'));
      },
      onImageFilesChange(event) {
        const files = Array.from(event.target.files);

        const url = event.target.dataset.directUploadUrl;
        files.forEach(file => this.addImage(file, url));
        event.target.value = null;
      },
      addImage(file, url) {
        const newImage = _.cloneDeep(this.blueprint);
        newImage.uploader = new Uploader(file, url, 'image');
        newImage.key = `KEY_${Math.round(Math.random() * 1000000000)}`;

        this.images.unshift(newImage);
      },
      removeImage(image, index) {
        if(image.id) {
          image._destroy = this.imageMarkedForDeletion(image) ? '0' : '1';
        } else {
          this.images.splice(index, 1);
        }
      },
      imageMarkedForDeletion(image) {
        return image._destroy === '1';
      },
      imageOrderOptions(){
          return this.images.map((el, index) => (
              {
                  label: index + 1,
                  value: index + 1
              }
          ))
      },
      onImageOrderChange(index, $event){
          // console.log(index, $event);
          this.$nextTick(() => {
              let image = this.images.splice(index, 1);
              this.images.splice($event, 0, image[0]);
          });
      }
    },

    watch: {
      requestsRunning(newValue) {
        this.$emit('requestsRunning', newValue);
      }
    }
  }
</script>