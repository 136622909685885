<script>
  import customBtnCheckbox from '../components/custom-btn-checkbox'
  import customBtnRadioGroup from '../components/custom-btn-radio-group'
  import customVueSelect from '../components/custom-vue-select'

  export default {
    name: "seo-text-edit-form",
    template: '#Vue-SeoTextEditForm',
    components: {
      customBtnCheckbox,
      customBtnRadioGroup,
      customVueSelect
    },
    props: {
      seo_text: {type: String},
      p_make: {type: String},
      p_model: {type: String},
      p_models: {type: Array},
      model_url: {type: String}
    },
    data() {
      return {
        jSeoText: JSON.parse(this.seo_text),
        jMake: JSON.parse(this.p_make),
        jModel: JSON.parse(this.p_model),
        oType: 'global',
        models: this.p_models || [],
        titleCount: this.titleCounter(),
        textCount: this.textCounter(),
        metaDescCount: this.metaDescCounter()
      }
    },
    computed: {
      make_id() {
        return this.jMake && this.jMake.id ? this.jMake.id : null;
      },
      model_id() {
        return this.jModel && this.jModel.id ? this.jModel.id : null;
      }
    },
    mounted() {
      this.oType = this.jSeoText.form_target_type;
      this.targetTypeChanged(this.jSeoText.form_target_type);
      this.titleCounter();
      this.textCounter();
      this.metaDescCounter();
    },
    watch: {

      'jSeoText.form_target_type': {
        // immediate: true,
        handler: function (form_target_type) {
          console.log('WATCH form_target_type: ', form_target_type);
          this.targetTypeChanged(form_target_type);
        },
        deep: true
      },

      'jMake.id': {
        handler: function (make_id) {
          if (this.model_url && make_id != null) {
            const request = $.get(this.model_url, {make: make_id});
            request.done((result) => {
              // console.log('RESULT:', result);
              this.models = result.models;

              let model_id = this.model_id;
              if (!this.models.find(function (element) {
                return element.value === model_id
              })) {
                this.jModel = {};
              }

            });
          } else {
            this.models = [];
            this.jModel = {};
          }

        },
        deep: true
      }

    },
    methods: {

      test(e) {
        console.log('TEST!', this.oType, this.jSeoText.target_id);
      },

      targetTypeChanged(type) {
        if (!type || typeof (type) === 'undefined' || type === '' || type === 'global') {
          this.$refs.make_select.classList.add('d-none');
          this.$refs.model_select.classList.add('d-none');
          this.oType = 'global';
          return;
        }

        if (type === 'make') {
          this.$refs.make_select.classList.remove('d-none');
          this.$refs.model_select.classList.add('d-none');

          if (this.oType !== 'make') {
            this.jModel = {};
            this.oType = 'make';
          }
          this.jSeoText.target_id = this.jMake ? this.jMake.id : null;
          console.log('SWITCHED TO MAKE', this.jMake.id);

        } else if (type === 'model') {
          this.$refs.make_select.classList.remove('d-none');
          this.$refs.model_select.classList.remove('d-none');

          if (this.oType !== 'model') {
            this.oType = 'model';
            this.jSeoText.target_id = null;
          }
          console.log('SWITCHED TO MODEL', this.jModel.id);

        } else {
          this.$refs.make_select.classList.add('d-none');
          this.$refs.model_select.classList.add('d-none');
          this.jModel = {};
          this.jMake.id = null;
          this.oType = 'global';
          this.jSeoText.target_id = null;
        }
      },

      makeIDChanged(new_id) {
        console.log('MAKE ID CHANGED!', new_id);
        if (this.jSeoText === null || typeof (this.jSeoText) === 'undefined') {
          return;
        }

        if (this.jSeoText.form_target_type === 'make') {
          this.jSeoText.target_id = new_id;
        }

        console.log('NEW target_id:', this.jSeoText.target_id);
      },

      modelIDChanged(new_id) {
        console.log('MODEL ID CHANGED!', new_id);
        if (this.jSeoText === null || typeof (this.jSeoText) === 'undefined') {
          return;
        }

        if (this.jSeoText.form_target_type === 'model') {
          this.jSeoText.target_id = new_id;
        }

        console.log('NEW target_id:', this.jSeoText.target_id);
      },

      titleCounter() {
        if (this.jSeoText !== null && typeof (this.jSeoText) !== 'undefined' && this.jSeoText.title) {
          this.titleCount = this.jSeoText.title.length;
        } else {
          this.titleCount = 0;
        }
      },

      textCounter() {
        if (this.jSeoText !== null && typeof (this.jSeoText) !== 'undefined' && this.jSeoText.text) {
          this.textCount = this.jSeoText.text.length;
        } else {
          this.textCount = 0;
        }
      },

      metaDescCounter() {
        if (this.jSeoText !== null && typeof (this.jSeoText) !== 'undefined' && this.jSeoText.meta_description) {
          this.metaDescCount = this.jSeoText.meta_description.length;
        } else {
          this.metaDescCount = 0;
        }
      },

      validateSubmit() {
        let editForm = document.getElementById('form-seo-text-edit');
        editForm.submit();
      }
    }
  }
</script>
