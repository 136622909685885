<template>
  <div class="row">
    <div class="col-12">
      <div class="embed-responsive newsletter-iframe" :id="iframeId">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "hubspot-form",
  props: {
    portalId: {type: String},
    formId: {type: String},
  },
  mounted() {
    const formElement = `hbspt.forms.create({region: "eu1", portalId: "${this.portalId}", formId: "${this.formId}"});`
    const hsScript = document.createElement('script')
    hsScript.setAttribute('charset', 'utf-8')
    hsScript.setAttribute('type', 'text/javascript')
    hsScript.setAttribute('src', '//js-eu1.hsforms.net/forms/embed/v2.js')
    hsScript.onload = () => {
      const hsForm = document.getElementById(this.iframeId);
      if (hsForm) {
        const hsFormScript = document.createElement('script')
        hsFormScript.setAttribute('type', 'text/javascript')
        hsFormScript.innerHTML = formElement
        hsForm.appendChild(hsFormScript)
      }
    }
    document.head.appendChild(hsScript)
  },
  data() {
    return {
      iframeId: `hubspotFormIframeContainer-${Date.now()}-${Math.floor(Math.random() * 1000000)}`
    }
  },
}
</script>
