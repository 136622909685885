<template>
    <modal @close='close' :buttons=buttons>
        <template slot="header"><h3>{{title}}</h3></template>
        <template slot="body"><div v-html="question"></div></template>
        <template slot="footer"></template>
    </modal>
</template>

<script>
  import Modal from './modal';
  import ModalControl from '../../packs/modal-control';

  export default {
    components: {Modal},
    name: "modal-confirm",
    props: {
        modal_classes:  {type: String},
        footer_classes: {type: String},
        title:          {type: String},
        question:       {type: String},
        buttons:        {type: Array, default: () => [ModalControl.BUTTON_CLOSE]
        },
    },
    data() {
      return {}
    },
    methods: {
      close() {
        this.$emit('close');
      }
    }
  }
</script>
