<template>
    <!--
      Do not return scopedSlots from the computed vueSelectBinds!
      Passing on scopedSlots is not a standard vue feature, and there is a hack in application.js that enables it.
      The hack deletes the $scopedSlots value from whatever is passed to v-bind (in this case the computed result of
      vueSelectBinds), otherwise it would end up on the root element of VueSelect. This leads to a subtle bug where
      sometimes the passed in slots get lost (because they are deleted by the hack and the computed result of
      vueSelectBinds does no get updated).
    -->
    <div class="custom-vue-select">
        <input v-if="name" type="hidden" :name="name" v-model="selectedValue">
        <VueSelect
                v-bind="{...vueSelectBinds, $scopedSlots}"
                @input="onVueSelectInput"
        >
        </VueSelect>
    </div>
</template>

<script>
    import VueSelect from 'vue-select';
    import _ from 'lodash';

    const vueSelectMixinsProps = Object.assign(
        {},
        ..._(VueSelect.mixins).map('props').filter().value()
    );

    const localProps = {
        name: {type: String },
        keepSelectionOnOptionsChange: {type: Boolean, default: false},
    };

    export default {
        name: "custom-vue-select",
        props: {
            // These are the props that get passed on to VueSelect
            ...VueSelect.props,
            ...vueSelectMixinsProps,
            // These are the props for this component
            ...localProps,
        },
        components: {
            VueSelect
        },
        data() {
            return {
                safeOptions: this.options

            };
        },
        methods: {
            // There is a prop called onInput, so we can't call this method onInput
            onVueSelectInput(selected) {
                const value = selected && selected.value;
                this.$emit('input', value);
            },
        },
        computed: {
            selectedValue(){
              return this.selectedOption ? this.selectedOption.value : '';
            },
            vueSelectBinds() {
                return {
                    // Pass on all props passed to this component except the ones meant for this component
                    ..._(this.$props).pickBy((_value, key) => !localProps[key]).value(),
                    options: this.safeOptions,
                    value: this.selectedOption,
                }
            },
            selectedOption() {
                // When loading options via ajax, the options change based on what was searched. If we deselect the current
                // value as soon as the options do not contain it, the following undesired behaviour would occur:
                // 1. The user enters a search and selects a value
                // 2. The user enters another search (that does not contain the current value).
                //    The options get replaced and the value gets deselected
                // 3. The user closes the search without selecting a value, and the previous selection is lost.
                // The solution is to disable deselecting the value when the options change. This is done by storing the options
                // in this.$options.options, which is not reactive.
                const options = this.keepSelectionOnOptionsChange ? this.$options.options : this.safeOptions;
              // console.log('>>>>>>>>>>>>>>>>  OPTIONS:', options);

                let i = 0,
                    length = options.length,
                    currentValue = this.value,
                    returnValue = null;

                if (_.isArray(currentValue) && currentValue.length === 1 ) {
                  currentValue = currentValue[0];
                }

                for (i; i<length; i++) {
                  let currentOptionOrGroup = options[i];

                    // console.log(' - CURRENT:', currentOptionOrGroup);
                    // console.log(' - VALUE  :', currentValue);

                  // STEP 1: Check if group or option (groups don't have a value property)
                  if ( currentOptionOrGroup.hasOwnProperty('value') ) {
                    // console.log('   - item: ', currentValue, currentOptionOrGroup.value);
                    // console.log('     - RETURN: ', returnValue);
                    if ( currentOptionOrGroup.value == currentValue ) {
                      returnValue = currentOptionOrGroup;
                      break;
                    }
                  }
                  else {
                    // console.log('   - group: ', currentOptionOrGroup);

                    // STEP 2: Check if selectable group (selectable groups have 'option_values' property)
                    if ( _.isArray(currentValue) && currentOptionOrGroup.hasOwnProperty('option_values') )
                    {
                      // console.log('     - NEW GROUP');
                      // console.log('        - ', currentOptionOrGroup.option_values, currentValue, _.isEqual(currentOptionOrGroup.option_values, currentValue));
                      returnValue = undefined;
                      if (_.isEqual(currentOptionOrGroup.option_values, currentValue)) {
                        returnValue = {label: currentOptionOrGroup.title, optgroup: true, value: currentOptionOrGroup.option_values};
                      }
                    }
                    else {
                        // console.log('     - CLASSIC GROUP');
                        returnValue = currentOptionOrGroup.options.find( function(option) {
                            // console.log('     - option: ', option.value, currentValue);
                          return option.value == currentValue
                        });
                    }

                    // console.log('     - RETURN: ', returnValue);
                    if ( returnValue ) {
                      break;
                    }
                  }
                }

                return returnValue;
            }
        },
        watch: {
            options: {
                immediate: true,
                handler(newOptions, oldOptions) {
                    if(!_.isEqual(newOptions, oldOptions)) {
                        this.safeOptions = newOptions;
                        this.$options.options = newOptions;

                    }
                }
            }
        }
    }
</script>
