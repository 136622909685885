<script>
  import customVueSelect from '../components/custom-vue-select'
  import customVueSlider from '../components/custom-vue-slider'
  import customCheckboxBtn from '../components/custom-checkbox-btn'
  import customRadioBtnGroup from '../components/custom-radio-btn-group'
  import {t} from '../i18n/i18n';

  import _ from 'lodash';

  export default {
    name: "offer-search-form",
    components: {
      customVueSelect, customVueSlider, customRadioBtnGroup, customCheckboxBtn
    },
    props: {
      delivery_options: {type: Object, required: true},
      electric_range_options: {type: Object, required: true},
      price_options: {type: Object, required: true},
      ps_options: {type: Object, required: true},
      initial_search_params: {type: Object, required: true},
      count_url: {type: String, required: true},
      model_url: {type: String, required: true},
      electric_fuel_id: {type: Number, required: true},
      initial_models: {type: Array},
      show_car_count: {type: String, default: 'true'}
    },
    data() {
      return {
        search_params: this.initial_search_params,
        car_count: "",
        models: this.initial_models || [],
        sliderFuelElectricSelected: false,

        request: null,
        requestRunning: false,
        requestErrorMsg: null,
      }
    },
    computed: {
      make_id() {
        return this.search_params.make ? this.search_params.make : null;
      },
      model_id() {
        return this.search_params.model ? this.search_params.model : null;
      },
    },
    watch: {
      make_id(make_id) {
        if (this.model_url && make_id != null) {
          this.requestRunning = true;

          const reqSuccess = (response) => {
            if (response.data.hasOwnProperty('models') && response.data.models ) {
              this.models = response.data.models;

              let i = 0,
                  length = this.models.length,
                  currentModelOrGroup = null,
                  searchedModel = null;

              for (i; i < length; i++) {
                currentModelOrGroup = this.models[i];
                if (currentModelOrGroup.hasOwnProperty('value')) {
                  if (currentModelOrGroup.value === model_id) {
                    searchedModel = currentModelOrGroup;
                    break;
                  }
                }
                else {
                  searchedModel = currentModelOrGroup.options.find(function (option) {
                    return option.value === model_id;
                  });
                  if (searchedModel) {
                    break;
                  }
                }
              }

              if (!searchedModel) {
                this.search_params.model = null;
              }
            }
            else{
              this.models = [];
              this.search_params.model = null;
            }
          };
          const reqFinally = (response) => {
            this.requestRunning = false;
          };
          const reqError = (response) => {
            this.requestErrorMsg = this.t('requests.exception', {code: response.status});
          };
          const before = (request) => {
            if (this.request) {
              this.request.abort();
            }
            this.request = request;
          };

          this.request = this.$http.get(
            this.model_url,
            {
              params: {
                make: make_id
              }
            },
            before
          ).then(reqSuccess).finally(reqFinally).catch(reqError);
        } else {
          this.models = [];
          this.search_params.model = null;
        }
      },

      search_params: {
        handler: function (search_params) {
          if (!_.isEmpty(search_params.electric_range)) {
            let electric_range_changed = (_.first(search_params.electric_range) !== this.electric_range_options.min || _.last(search_params.electric_range) !== this.electric_range_options.max)
            if (electric_range_changed) {
              search_params.fuel = this.electric_fuel_id;
              this.sliderFuelElectricSelected = true;
            } else {
              if (this.sliderFuelElectricSelected) {
                search_params.fuel = null;
                this.sliderFuelElectricSelected = false;
              }
            }
          }

          if (this.count_url && this.show_car_count === 'true') {
            this.requestRunning = true;

            const reqSuccess = (response) => {
              this.car_count = response.data.count;
            }
            const reqFinally = (response) => {
              this.requestRunning = false;
            };
            const reqError = (response) => {
              this.requestErrorMsg = this.t('requests.exception', {code: response.status});
            };
            const before = (request) => {
              if (this.request) {
                this.request.abort();
              }
              this.request = request;
            };

            this.request = this.$http.get(
              this.count_url,
              {
                params: search_params
              },
              before
            ).then(reqSuccess).finally(reqFinally).catch(reqError);
          }
        },
        deep: true
      }
    },
    methods: {
      t,
      sliderRefresh() {
        window.setTimeout(() => {
          this.$children.forEach(function (child) {
            if (child.$refs.slider && typeof (child.$refs.slider.refresh) === "function") {
              child.$refs.slider.refresh();
            }
          });
        }, 10);
      }
    }
  }
</script>
