<template>
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text rounded-0">{{prefix}}</span>
    </div>
    <numeric-input v-model="internalValue" :precision="precision" :min="min" :max="max" :separator="separator"
                   :delimiter="delimiter" :locale="locale" v-bind="$attrs"/>
  </div>
</template>

<script>
  import NumericInput from './numeric-input';
  import _ from 'lodash';
  import {t_scoped} from '../i18n/i18n'
  const t = t_scoped('inputs');

  export default {
    inheritAttrs: false,
    props: {
      value: {required: true},
      precision: {required: false, default: 2},
      min: {required: false},
      max: {required: false},
      separator: {type: String, required: true}, // decimal symbol
      delimiter: {type: String, required: true}, // thousands separator
      locale: {type: String, required: false, default: undefined},

      isPerMill: {type: Boolean},

    },
    components: {NumericInput},
    data() {
      return {
        internalValue: this.toDisplayValue(this.value),
      }
    },
    methods: {
      toDisplayValue(value) {
        if(_.isFinite(value)) {
          return this.isPerMill ? value : value / 10
        } else {
          return value;
        }
      },
      fromDisplayValue(value) {
        if(_.isFinite(value)) {
          return this.isPerMill ? value : value * 10
        } else {
          return value;
        }
      },
    },
    computed: {
      prefix() {
        return this.isPerMill ? t('per_mill.symbol') : t('percent.symbol');
      }
    },
    watch: {
      internalValue(newValue) {
        this.$emit('input', this.fromDisplayValue(newValue));
      },
      value(newValue) {
        this.internalValue = this.toDisplayValue(newValue)
      }
    }
  }
</script>
