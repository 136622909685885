import {DirectUpload} from "@rails/activestorage";

export default class Uploader {
  constructor(file, url, type) {
    this.running = true;
    this.loaded = 0;
    this.total = null;
    this.directUpload = new DirectUpload(file, url, this);
    this.directUpload.create(this.onUpload.bind(this));
    this.type = type;
  }

  onUpload(error, blob) {
    this.running = false;
    if (error) {
      this.error = error;
    } else {
      this.blob = blob;
      if (this.type === 'image') {
        this.url = `/rails/active_storage/blobs/${this.blob.signed_id}/none.jpg`
      }
      else {
        this.url = '#'
      }
    }
  }

  // This will be called by DirectUpload once the upload has started
  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", event => this.directUploadDidProgress(event));
  }

  directUploadDidProgress(event) {
    // console.log('directUploadDidProgress', event.loaded, event.total, event);
    this.loaded = event.loaded;
    this.total = event.total;
  }
}
