<script>
import numericInput from '../components/numeric-input';
import percentInput from '../components/percent-input';
import customBtnCheckbox from '../components/custom-btn-checkbox';
import customBtnRadioGroup from '../components/custom-btn-radio-group';
import userSearchForm from '../components/user-search-form';
import {t} from '../i18n/i18n';

export default {
  name: "dealer-edit-form",
  template: '#Vue-DealerEditForm',
  components: {
    customBtnCheckbox,
    customBtnRadioGroup,
    numericInput,
    percentInput,
    userSearchForm,
  },
  props: {
    dealer: {type: String},
  },
  data() {
    return {
      jDealer: JSON.parse(this.dealer),
      hasErros: false,
      activeApis: 0,
      index: 0,
    }
  },
  computed: {
    hasAPIErrors() {
      return this.hasErros;
    },
  },
  methods: {
    t,

    validateSubmit() {
      let editForm = document.getElementById('form-dealer-edit');

      this.activeApis = 0;
      if (this.jDealer.autohausen_de_active) {
        this.activeApis++;
      }
      if (this.jDealer.dotzilla_de_active) {
        this.activeApis++;
      }
      if (this.jDealer.eautoseller_de_active) {
        this.activeApis++;
      }
      if (this.jDealer.mobile_de_active) {
        this.activeApis++;
      }
      if (this.jDealer.aos24_active) {
        this.activeApis++;
      }
      if (this.jDealer.cargate_active) {
        this.activeApis++;
      }
      if (this.jDealer.webmobil24_active) {
        this.activeApis++;
      }
      if (this.jDealer.easycarsales_active) {
        this.activeApis++;
      }
      if (this.jDealer.carliner_active) {
        this.activeApis++;
      }

      if (this.activeApis > 1) {
        this.jDealer.errors['one_active_api_only'] = t('settings.errors.one_active_api_only');
        this.hasErros = true;
      }
      else {
        delete this.jDealer.errors.one_active_api_only;
        this.hasErros = false;

        editForm.submit();
      }
    },

    /**
     * Needed to correct forms name attribute with rails form helper index,
     * used to be able to easily take form submit result into activeRecord update.
     *
     * @param name
     * @param indexes Array   Array of indexes, depending on depth of cascade VUE component is placed
     * @returns {*}
     */
    correctNameIndex(name, indexes) {
      let counter = 0;
      return name.replace(/\[\d+\]/gm, () => {
        let returnVal = '['.concat(indexes[counter], ']');
        counter++;
        return returnVal;
      });
    },

    /**
     * Needed to correct forms id attribute with rails form helper index,
     * used to be able to easily take form submit result into activeRecord update.
     *
     * @param id
     * @param indexes Array   Array of indexes, depending on depth of cascade VUE component is placed
     * @returns {*}
     */
    correctIdIndex(id, indexes) {
      let counter = 0;
      return id.replace(/_\d+_/gm, () => {
        let returnVal = '_'.concat(indexes[counter], '_');
        counter++;
        return returnVal;
      });
    },
  },
}
</script>
