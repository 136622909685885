<script>
  import customVueSelect from '../components/custom-vue-select'
  import customBtnCheckbox from '../components/custom-btn-checkbox'
  import customBtnRadioGroup from '../components/custom-btn-radio-group'
  import dateTimeInput from '../components/date-time-input'
  import axios from 'axios'
  import VueAxios from 'vue-axios'

  export default {
    template: '#Vue-CarImportFilterForm',
    components: {
      customVueSelect,
      customBtnCheckbox,
      customBtnRadioGroup,
      dateTimeInput
    },
    props: {
      search_params: {type: String},
      ui_params: {type: String},
      count_url: {type: String},
      model_url: {type: String},
      p_models: {type: Array},
    },
    data() {
      return {
        count_result: null,
        models: this.p_models,
        j_search_params: JSON.parse(this.search_params),
        ui_p: JSON.parse(this.ui_params)
      }
    },
    computed: {
    },
    watch: {

      'j_search_params.make': {
        // immediate: true,
        handler: function (make_id) {
          if (this.model_url && make_id != null) {
            const request = $.get(this.model_url, {make: make_id});
            return request.done((result) => {
              this.models = result.models;

              let model_id = this.model_id;
              if (!this.models.find(function (element) {
                return element.value === model_id
              })) {
                this.j_search_params.model = null;
              }

            });
          } else {
            this.models = [];
            this.j_search_params.model = null;
          }
        }
      },

      j_search_params: {
        immediate: true,
        handler: function (search_params) {
          if (this.count_url) {
            const request = $.get(this.count_url, search_params);
            return request.done((result) => {
              if ( result && typeof result !== 'undefined' && result.count > 0 ) {
                // this.count_result = result.count;
                this.count_result = '*';
              }
              else {
                this.count_result = '';
              }
            });
          }
        },
        deep: true
      }
    },
    methods: {
      test() {
        console.log('TEST!', this.dealer_count);
      }
    }
  }
</script>
