<template>
    <div class="custom-btn-radio-group d-flex flex-wrap">
        <template v-for="item in content_list">
            <custom-btn-checkbox
                    :ref="'cb'.concat('_', item.id)"
                    :label="item.label"
                    v-model="active[item.id]"
                    root_classes="flex-grow-1"
                    group_classes=""
                    label_classes="btn-secondary btn-block"
            />
        </template>
        <input ref="hiddenResult" type="hidden" value="" :name="name">
    </div>
</template>

<script>
/**
 *
 * !!! TODO: REPLACE this component !!!
 *
 */
  import customBtnCheckbox from '../components/custom-btn-checkbox'

  export default {
    /**
     * Custom component internal non-reactive data
     * accessible via this.$options.[NAME_OF_PROPERTY]
     *
     * @see https://github.com/vuejs/vue/issues/1988#issuecomment-354144340
     */
    cActive: {
      storage: null,
      get: function () {
        return this.storage;
      },
      set: function (params) {
        this.storage = params;
      }
    },
    oActive: {
      storage: null,
      get: function () {
        return this.storage;
      },
      set: function (params) {
        this.storage = params;
      }
    },

    /**
     * Common VUE options/hooks
     */
    components: {customBtnCheckbox},
    props: {

      // value of html name attribute needed by form submit
      name: {type: String},

      // object which defines IDs and labels of group's buttons
      content_list: {type: Array},

      // switches group behaviour: radio or checkbox
      is_radio: {type: Boolean, default: true},

      // controls if no selection allowed
      no_choice: {type: Boolean, default: false},

      // basic value
      value: {}
    },
    data() {
      return {
        selected: this.value,
        active: this.checkInitialActive(),
      };
    },
    mounted() {
      this.prepareSelectedState(this.active);
    },
    watch: {

      active: {
        handler: function (active) {
          // clone active
          this.$options.cActive = JSON.parse(JSON.stringify(this.active));

          let ref              = this,
              hasChanges       = false,
              oKeys            = Object.keys(active),
              cnt              = 0,
              cID              = -1,
              default_selected = oKeys[0];

          for (cnt; cnt < oKeys.length; cnt++) {
            cID = oKeys[cnt];

            if (this.is_radio && (active[cID] !== this.$options.oActive[cID])) {
              default_selected = cID;
              if (this.getSelectedCnt() > 1) {
                ref.deSelectAllExcept(cID, ref);
                hasChanges = true;
              }
            }
          }

          if (!this.no_choice && (this.getSelectedCnt() <= 0)) {
            this.$options.cActive[default_selected] = true;
            hasChanges = true;
          }

          // only update on changes
          if (hasChanges) {

            // update active
            this.active = JSON.parse(JSON.stringify(this.$options.cActive));

            // be sure child components are updated
            this.updateManually();
          }

          // always update origin
          this.$options.oActive = JSON.parse(JSON.stringify(this.$options.cActive));

          // emit changes on active as changes on selected to parent(s)
          this.$emit('input', this.getSelectedState(this.active));
        },
        deep: true
      }
    },
    methods: {

      checkInitialActive() {
        let ref    = this,
            result = {},
            cID    = -1,
            cb     = null;

        Object.keys(this.content_list).forEach(function (index) {

          cID = ref.content_list[index].id;
          cb = ref.$refs['cb_' + cID];

          if (cb !== null && typeof (cb) !== "undefined") {
            result[cID] = cb[0]._data.active;
          } else if (ref.value !== null && typeof (ref.value) !== 'undefined') {

            // check if incoming value is array (as used by checkbox behaviour with multiple selected)
            if (!Array.isArray(ref.value)) {
              result[cID] = (cID.toString() === ref.value.toString());
            } else {
              result[cID] = ref.value.includes(cID);
            }
          } else {
            result[cID] = false;
          }
        });

        this.$options.oActive = JSON.parse(JSON.stringify(result));
        return result;
      },

      deSelectAllExcept(id, ref) {
        Object.keys(this.$options.cActive).forEach(function (cID) {
          if (cID !== id) {
            ref.$options.cActive[cID] = false;
          }
        });
      },

      getSelectedCnt() {
        let cnt = 0,
            ref = this;
        Object.keys(this.$options.cActive).forEach(function (cID) {
          if (ref.$options.cActive[cID] === true) {
            cnt++;
          }
        });
        return cnt;
      },

      updateManually() {
        let ref = this,
            cID = -1,
            cb  = null;

        Object.keys(this.content_list).forEach(function (index) {
          cID = ref.content_list[index].id;
          cb = ref.$refs['cb_' + cID];
          cb[0]._data.active = ref.active[cID];
        });
      },

      prepareSelectedState(active) {
        let oKeys  = Object.keys(active),
            cnt    = 0,
            cID    = -1,
            result = !this.is_radio ? [] : null;

        for (cnt; cnt < oKeys.length; cnt++) {
          cID = oKeys[cnt];

          if (active[cID]) {
            if (!this.is_radio) {
              result.push(cID);
            } else {
              result = cID;
              break;
            }
          }
        }

        this.$refs.hiddenResult.value = result;

        return result;
      },

      getSelectedState(active) {
        return this.prepareSelectedState(active);
      }

    }
  }
</script>
