<script>
  import Modal from './modal';
  import ModalControl from '../../packs/modal-control';

  export default {
    template: '#Vue-ModalSlider',
    components: {Modal},
    props: {
      no_button: {type: Boolean, default: false},
      modal_classes: {type: Array},
      dialog_classes: {type: Array},
      content_classes: {type: Array},
      header_classes: {type: Array},
      body_classes: {type: Array},
      footer_classes: {type: Array},
      active_slide: {type: String}
    },
    data() {
      return {}
    },
    mounted() {
      this.setActiveSlide();
    },
    methods: {
      close() {
        this.$emit('close');
      },
      setActiveSlide(){
        this.$refs.modalSliderCont.children[this.active_slide].classList.add('active');
      }
    }
  }
</script>
