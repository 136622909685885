<script>
  import Modal from './modal';
  import ModalControl from '../../packs/modal-control';
  import axios from 'axios'
  import VueAxios from 'vue-axios'

  export default {
    template: '#Vue-ModalLogin',
    components: {Modal},
    props: {
      no_button: {type: Boolean, default: false},
      modal_classes: {type: Array},
      content_classes: {type: Array},
      dialog_classes: {type: Array},
      header_classes: {type: Array},
      body_classes: {type: Array},
      footer_classes: {type: Array}
    },
    data() {
      return {
        errorState: {emailHasErrors: false, passwordHasErrors: false, errors: []},
        // errorState:     {type: Object},//{emailHasErrors: false, passwordHasErrors: false, errors: []},
        email: null,
        password: null
      }
    },
    // beforeMount() {
    //   this.errorState = { errors: [] };
    // },
    methods: {
      close() {
        this.$emit('close');
      },
      signIn: function(e) {
        if (e && e.keyCode !== 13) {
          // not ENTER pressed yet, ignore!
          return;
        }

        this.errorState = {emailHasErrors: false, passwordHasErrors: false, errors: []};

        if (!this.email) {
          this.errorState.emailHasErrors = true;
          this.errorState.errors.push('Email required.');
        } else if (!this.validEmail(this.email)) {
          this.errorState.emailHasErrors = true;
          this.errorState.errors.push('Valid email required.');
        }
        if (!this.password) {
          this.errorState.passwordHasErrors = true;
          this.errorState.errors.push("Password required.");
        }

        if (!this.errorState.errors.length) {

          this.$http

            // make login request
            .post(
              '/users/sign_in',
              // payload data:
              {
                user: {
                  email: this.email,
                  password: this.password,
                  remember_me: 1
                }
              }
            )

            // handle successful request
            .then(response => {
              //console.log(response);

              // STEP 1: hide login modal
              ModalControl.hide();

              // STEP 2: show successful login message modal & reload after 3s or by click
              var loginTimeOutID = window.setTimeout(function() {
                location.reload();
              }, 3000);

              ModalControl.show(
                'modal-message',
                {
                  message: 'Sie wurden erfolgreich eingeloggt!',
                  message_classes:      'static btn-gradient btn-primary',
                  on_close_callback:    function(){
                    window.clearTimeout(loginTimeOutID);
                    location.reload();
                  }
                }
              );
            })

            // handle failed request
            .catch(error => {
              console.error(error);

              // STEP 1: hide login modal
              ModalControl.hide();

              // STEP 2: show failed login message modal
              var loginTimeOutID = window.setTimeout(function() {
                location.reload();
              }, 3000);

              ModalControl.show(
                'modal-message',
                {
                  message: 'Login fehlgeschlagen! Überprüfen Sie bitte E-Mail und Passwort, ' +
                      'versuchen Sie es später erneut oder wenden Sie sich an an den Support unter: ' +
                      'leasing@getyourdrive.com.',
                  message_classes:      'static btn-gradient btn-primary',
                  on_close_callback:    function(){
                    window.clearTimeout(loginTimeOutID);
                    location.reload();
                  }
                }
              );

            })
        }
      },
      validEmail: function (email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      }
    }
  }
</script>
