import { v4 as uuid } from 'uuid';

let onChangeCallback = null;
const stack = [];

export default {
  show(component, props) {
    //console.log('ModalControl::show  >>> ',component, props);
    const entry = {
      key: uuid(),
      component, props,
    };
    const promise = new Promise((resolve, _reject) => entry.resolve = resolve);
    stack.push(entry);
    onChangeCallback(stack);

    if(stack.length > 0) {
      document.body.classList.add('no-scroll');
    }

    return promise;
  },
  hide(payload) {
    const entry = stack.pop();
    if(entry) {
      onChangeCallback(stack);
      entry.resolve(payload);
    }

    if(stack.length === 0) {
      document.body.classList.remove('no-scroll');
    }

  },
  onChange(callback) {
    //console.log('ON CHANGE!', callback);
    onChangeCallback = callback;
  },

  BUTTON_CLOSE: {},
  BUTTON_CANCEL: {}
}
