<template>
    <div class="input-group-prepend">
        <div class="input-group-text rounded-0 border-right-0">
            <i v-if="!disabled" ref="icon" class="fas" :class="icon_classes"></i>
            <i v-else ref="icon" class="fas text-white" :class="icon_classes"></i>
        </div>
        <custom-vue-select v-if="!disabled" :name="name" v-model="selected" :class="select_classes" :options="select_options" @input="changed"></custom-vue-select>
        <custom-vue-select v-else :name="name" v-model="selected" class="disabled" :class="select_classes" :options="select_options" disabled="disabled"></custom-vue-select>

        <div v-if="destroy.active">
            <input type="hidden" :name="destroy.name.concat('[_id]')" :value="destroy.id">
            <input type="hidden" :name="destroy.name.concat('[_destroy]')" value="1">
        </div>
    </div>
</template>

<script>
  import customVueSelect from '../components/custom-vue-select'

  export default {
    components: {customVueSelect},
    props: {
        icon_classes: {type: String},
      select_classes: {type: String},
      highlight_classes: {type: Array},
      select_options: {type: Array},
      value: {},
      disabled: {type: Boolean},
      destroy: {type: Object},

      name: {type: String}
    },
    data(){
      return {
        selected: this.value
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.changed( this.value );
      })
    },
    methods: {
      changed(value) {
        let ref = this;
        if ( value !== null && typeof(value) !== 'undefined' && value !== '' ) {
          this.highlight_classes.forEach( function( highlightClass ){
            ref.$refs.icon.classList.add( highlightClass );
          });
          this.setDestroy(false);
        }
        else {
          this.highlight_classes.forEach( function( highlightClass ){
            ref.$refs.icon.classList.remove( highlightClass );
          });
          this.setDestroy(true);
        }
        this.$emit('input', this.selected);
      },

      hasDestroy() {
        return this.destroy !== null && typeof this.destroy !== 'undefined'
      },

      setDestroy(active) {
        if ( this.hasDestroy ) {
          this.destroy.active = active;
        }
      }
    }
  }
</script>
