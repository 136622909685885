<script>
  import customVueSelect from '../components/custom-vue-select'
  import customPrependSelect from '../components/custom-prepend-select'
  import customVueSlider from '../components/custom-vue-slider'
  import customBtnCheckbox from '../components/custom-btn-checkbox'
  import customRadioBtnGroup from '../components/custom-radio-btn-group'
  import dateTimeInput from '../components/date-time-input'
  import axios from 'axios'
  import VueAxios from 'vue-axios'

  export default {
    template: '#Vue-OfferFilterForm',
    components: {
      customVueSelect,
      customPrependSelect,
      customVueSlider,
      customBtnCheckbox,
      customRadioBtnGroup,
      dateTimeInput,
    },
    props: {
      search_params: {type: String},
      price_options: {type: Object, required: true},
      ps_options: {type: Object, required: true},
      first_registered_on_options: {type: Object, required: true},
      mileage_options: {type: Object, required: true},
      ui_params: {type: String},
      count_url: {type: String},
      model_url: {type: String},
      p_models: {type: Array},
    },
    data() {
      return {
        car_count: null,
        models: this.p_models,
        j_search_params: JSON.parse(this.search_params),
        ui_p: JSON.parse(this.ui_params)
      }
    },
    computed: {
      make_id() {
        return this.j_search_params.make ? this.j_search_params.make : null;
      },
      model_id() {
        return this.j_search_params.model ? this.j_search_params.model : null;
      }
    },
    watch: {
      'j_search_params.make': {
        // immediate: true,
        handler: function (make_id) {
          if (this.model_url && make_id != null) {
            const request = $.get(this.model_url, {make: make_id});
            return request.done((result) => {
              this.models = result.models;

              let model_id = this.model_id;
              if (!this.models.find(function (element) {
                return element.value === model_id
              })) {
                this.j_search_params.model = null;
              }

            });
          } else {
            this.models = [];
            this.j_search_params.model = null;
          }
        }
      },

      j_search_params: {
        handler: function (search_params) {
          if (this.count_url) {
            const request = $.get(this.count_url, search_params);
            return request.done((result) => {
              this.car_count = result.count
            });
          }
        },
        deep: true
      }
    },
    methods: {
      sliderRefresh() {
        window.setTimeout(() => {
          this.$children.forEach(function (child) {
            if (child.$refs.slider && typeof (child.$refs.slider.refresh) === "function") {
              child.$refs.slider.refresh();
            }
          });
        }, 10);
      },

      test() {
        console.log('TEST!', this.j_search_params);
      }
    }
  }
</script>
