<template>
  <div class="user-search-form">

    <div class="mb-3 bg-light">

      <!-- SEARCH FORM -->
      <div class="row p-3">
        <div class="col-3 text-white"><h4>Via Account</h4></div>
        <div class="col-7">
          <label for="email" class="sr-only">Email</label>
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text">@</div>
            </div>
            <input type="email" name="email" id="email"
                   placeholder="Account Emailadresse hier eintragen" class="form-control"
                   v-model="userAccountEmail"
            >
          </div>
        </div>
        <div class="col-2">
          <div title="Account suchen" class="btn btn-primary btn-block" @click="searchUserAccount">
            Suchen
            <span aria-hidden="true" role="status" class="spinner-border spinner-border-sm d-none"></span>
          </div>
        </div>
      </div>

      <!-- RESULT FORM -->
      <template v-if="users.length > 0">
        <div class="row px-3 mt-3 font-size-sm text-muted">
          <div class="col-3"></div>
          <div class="col-1 border-bottom">#</div>
          <div class="col-2 border-bottom">Vorname</div>
          <div class="col-2 border-bottom">Nachname</div>
          <div class="col-2 border-bottom">Email</div>
          <div class="col-2"></div>
        </div>
        <div class="row p-3 mt-3" v-for="(user, index) in users" :key="user.id">
          <div class="col-3"></div>
          <div class="col-1 text-left">{{ user.id }}</div>
          <div class="col-2">{{ user.first_name }}</div>
          <div class="col-2">{{ user.last_name }}</div>
          <div class="col-2">{{ user.email }}</div>
          <div class="col-2">
            <button class="btn btn-dark btn-block" type="button" @click="acceptUser(user.id)"
            >Zufügen
            </button>
          </div>
        </div>
      </template>
      <template v-if="users.length <= 0 && this.searchResultLoaded">
        <div class="row pl-3 pb-3">
          <div class="col-3"></div>
          <div class="col-9 ">
            <i class="fas fa-exclamation-triangle mr-1"></i>
            Es wurde kein Account mit dieser Emailadresse im System gefunden.</div>
        </div>
      </template>

    </div>

  </div>
</template>

<script>
import ModalControl from "../packs/modal-control";

export default {
  name: "user-search-form",

  props: {
    pDealerId: {type: String, required: true},
    pSearchUserUrl: {type: String, required: true},
    pAcceptUserUrl: {type: String, required: true},
  },

  data() {
    return {
      userAccountEmail: '',
      users: [],
      searchResultLoaded: false,
    }
  },

  methods: {
    searchUserAccount() {
      this.searchResultLoaded = false;
      const request = $.post(this.pSearchUserUrl, {email: this.userAccountEmail});
      request.done((result) => {
        this.users = result.users;
        this.searchResultLoaded = true;
      });
    },

    acceptUser(userId) {
      const request = $.post(
          this.pAcceptUserUrl,
          {
            op: 'add_employee',
            id: this.pDealerId,
            user_id: userId
          }
      );
      request.done((result) => {

        if ( result.state !== 'success' ){
          ModalControl.show(
              'modal-message',
              {
                message: 'Fehlgeschlagen: '+result.msg,
                message_classes: 'btn-danger p-5'
              }
          );
        }
        else {
          ModalControl.show(
              'modal-message',
              {
                message: 'Benutzer erfolgreich dem Händler hinzugefügt!',
                message_classes: 'btn-success p-5',
                on_close_callback: window.location.reload()
              }
          );
        }

      });
    },

  },

}
</script>

