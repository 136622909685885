import _ from 'lodash';

/**
 * Used for Form Error handling.
 *
 * Requires formErrors() {...} method to get object with stored errors (generated by backend in jbuilder
 * template with activemodel_errors_json2(@errors) helper) and
 * requires formErrorsPaths() {...} method to get 'paths' (as array of strings) of properties which can be used with
 * property name (as key) to access the error message within the above requested error object from backend.
 */
export default {
  methods: {

    /*
     * COMMON ELEMENT ERRORS
     */

    // API methods
    hasError(key){
      return !!this._getErrorByKey(key);
    },
    getError(key){
      let error = this._getErrorByKey(key);
      return error ? error.message : '';
    },

    _getErrorByKey(key){
      if (!this.formErrors || typeof(this.formErrors) === 'undefined') {
        // this.$log.log('  - no form errors!');
        return false;
      }

      let errorsArr = null,
        i = 0,
        pathsCnt = this.formErrorsPaths.length;

      // check given key on every available path until an error is found
      if (pathsCnt > 0) {
        for (i; i<pathsCnt; i++) {
          // this.$log.log('FORM-ERRORS-CHECK: ');
          // this.$log.log('  - errors:     ', this.formErrors);
          // this.$log.log('  - path + key: ', this.formErrorsPaths[i] + key);
          // this.$log.log('  - errorsArr: ', _.get(this.formErrors, this.formErrorsPaths[i] + key));
          errorsArr = _.get(this.formErrors, this.formErrorsPaths[i] + key);
          if (errorsArr && typeof errorsArr !== 'undefined' && errorsArr[0] &&
              errorsArr[0].hasOwnProperty('message'))
          {
            return errorsArr[0];
          }
        }
      }
      // be sure top level 'root' errors are checked
      else {
        errorsArr = _.get(this.formErrors, key);
        if (errorsArr && typeof errorsArr !== 'undefined' && errorsArr[0] &&
          errorsArr[0].hasOwnProperty('message'))
        {
          return errorsArr[0];
        }
      }

      return null;
    },


    /*
     * INDEXED ELEMENTS ERRORS
     * (when index could not set in formErrorsPaths() method & has to be set on field where error appears)
     *
     * i.e.
     *  - "partnership_conditions_attributes[0].condition"
     */

    // API methods
    hasErrorAt(key, index){
      return !!this._getErrorByKeyAndIndex(key, index);
    },
    getErrorAt(key, index){
      let error = this._getErrorByKeyAndIndex(key, index);
      return error ? error.message : '';
    },

    _getErrorByKeyAndIndex(key, index) {
      if (!this.formErrors || typeof(this.formErrors) === 'undefined') {
        return false;
      }

      let errorsArr = null,
        i = 0,
        pathsCnt = this.formErrorsPaths.length;

      for (i; i<pathsCnt; i++) {
        errorsArr = _.get(this.formErrors, this.formErrorsPaths[i] +'['+index+'].'+ key);

        if (errorsArr && typeof errorsArr !== 'undefined' && errorsArr[0].hasOwnProperty('message')) {
          return errorsArr[0];
        }
      }

      return null;
    },
  },
}