<template>
    <form class="Form" id="contact-request-info-form" @submit.prevent="contactRequestSubmit">
        <!-- name -->
        <div class="input-section mt-3 d-flex align-items-center"
             :class="hasError(CONTACT_REQUEST_KEYS.NAME) ? 'is-invalid' : ''"
        >
            <i class="fas fa-solid fa-user user-icon"></i>
            <input id="p-search-year" type="text" class="input-field w-100"
                   v-model="contact_request.name"
                   :placeholder="t_form('name')"
            >
        </div>
        <div class="invalid-feedback d-block text-left"
             v-if="hasError(CONTACT_REQUEST_KEYS.NAME)"
             v-html="getError(CONTACT_REQUEST_KEYS.NAME)"
        ></div>

        <!-- phone -->
        <div class="input-section mt-3 d-flex align-items-center"
             :class="hasError(CONTACT_REQUEST_KEYS.PHONE) ? 'is-invalid' : ''"
        >
            <i class="fas fa-solid fa-phone phone-icon"></i>
            <input id="p-search-year" type="text" class="input-field w-100"
                   v-model="contact_request.phone"
                   :placeholder="t_form('phone')"
            >
        </div>
        <div class="invalid-feedback d-block text-left"
             v-if="hasError(CONTACT_REQUEST_KEYS.PHONE)"
             v-html="getError(CONTACT_REQUEST_KEYS.PHONE)"
        ></div>

        <!-- email -->
        <div class="input-section mt-3 d-flex align-items-center"
             :class="hasError(CONTACT_REQUEST_KEYS.EMAIL) ? 'is-invalid' : ''"
        >
            <i class="far fa-solid fa-at email-icon"></i>
            <input id="p-search-year" type="text" class="input-field w-100"
                   v-model="contact_request.email"
                   :placeholder="t_form('email')"
            >
        </div>
        <div class="invalid-feedback d-block text-left"
             v-if="hasError(CONTACT_REQUEST_KEYS.EMAIL)"
             v-html="getError(CONTACT_REQUEST_KEYS.EMAIL)"
        ></div>

        <!-- company name -->
        <div class="input-section mt-3 d-flex align-items-center"
             :class="hasError(CONTACT_REQUEST_KEYS.COMPANY_NAME) ? 'is-invalid' : ''"
        >
            <i class="far fa-solid fa-building email-icon"></i>
            <input id="p-search-year" type="text" class="input-field w-100"
                   v-model="contact_request.company_name"
                   :placeholder="t_form('company_name')"
            >
        </div>
        <div class="invalid-feedback d-block text-left"
             v-if="hasError(CONTACT_REQUEST_KEYS.COMPANY_NAME)"
             v-html="getError(CONTACT_REQUEST_KEYS.COMPANY_NAME)"
        ></div>

        <template v-if="this.collect_business_data === 'true'">
          <!-- fleet size -->
          <div class="input-section mt-3 d-flex align-items-center"
               :class="hasError(CONTACT_REQUEST_KEYS.FLEET_SIZE) ? 'is-invalid' : ''"
          >
            <i class="far fa-solid fa-cars email-icon"></i>
            <input id="p-search-year" type="number" class="input-field w-100"
                   v-model="contact_request.fleet_size"
                   :placeholder="t_form('fleet_size')"
            >
          </div>
          <div class="invalid-feedback d-block text-left"
               v-if="hasError(CONTACT_REQUEST_KEYS.FLEET_SIZE)"
               v-html="getError(CONTACT_REQUEST_KEYS.FLEET_SIZE)"
          ></div>

          <!-- number of employees -->
          <div class="input-section mt-3 d-flex align-items-center"
               :class="hasError(CONTACT_REQUEST_KEYS.NUMBER_OF_EMPLOYEES) ? 'is-invalid' : ''"
          >
            <i class="far fa-solid fa-users email-icon"></i>
            <input id="p-search-year" type="number" class="input-field w-100"
                   v-model="contact_request.number_of_employees"
                   :placeholder="t_form('number_of_employees')"
            >
          </div>
          <div class="invalid-feedback d-block text-left"
               v-if="hasError(CONTACT_REQUEST_KEYS.NUMBER_OF_EMPLOYEES)"
               v-html="getError(CONTACT_REQUEST_KEYS.NUMBER_OF_EMPLOYEES)"
          ></div>
        </template>

        <!-- message -->
        <div class="input-section input-textarea-section mt-3 d-flex align-items-center"
             :class="hasError(CONTACT_REQUEST_KEYS.MESSAGE) ? 'is-invalid' : ''"
        >
            <textarea class="input-field w-100"
                      v-model="contact_request.message"
                      :placeholder="t_form('message')"
            ></textarea>
        </div>
        <div class="invalid-feedback d-block text-left"
             v-if="hasError(CONTACT_REQUEST_KEYS.MESSAGE)"
             v-html="getError(CONTACT_REQUEST_KEYS.MESSAGE)"
        ></div>

        <div class="input-checkbox mt-3 d-flex">
            <input class="checkbox"
                   :class="hasError(CONTACT_REQUEST_KEYS.TERM_CONDITIONS) ? 'is-invalid' : ''"
                   type="checkbox"
                   value="1"
                   v-model="contact_request.term_conditions"
            >
            <label class="checkbox-label" for="contact_request_term_conditions">
                Ich akzeptiere die <a class="text-light link" target="_blank" href="/datenschutz">Datenschutzbestimmungen</a>
            </label>
        </div>
        <div class="invalid-feedback d-block text-left"
             v-if="hasError(CONTACT_REQUEST_KEYS.TERM_CONDITIONS)"
             v-html="getError(CONTACT_REQUEST_KEYS.TERM_CONDITIONS)"
        ></div>

        <button type="submit" class="submit-btn mt-3"
                :class="this.processing ? 'disabled' : ''"
                :disabled="this.processing"
        >
            <span class="label">{{ t_form('submit') }}</span>
            <i v-if="this.processing" class="fas fa-spinner fa-spin text-white"></i>
            <i v-else class="far fa-solid fa-paper-plane text-white"></i>
        </button>
        <div class="invalid-feedback d-block text-left"
             v-if="this.processingErrors"
             v-html="this.processingErrors"
        ></div>
    </form>
</template>

<script>
import FormErrors from "../mixins/form-errors";
import api from "../utils/api";
import {t_scoped} from '../i18n/i18n';

const t_form = t_scoped('contact_request.form');
const CONTACT_REQUEST_KEYS = {
    NAME: 'name',
    PHONE: 'phone',
    EMAIL: 'email',
    COMPANY_NAME: 'company_name',
    FLEET_SIZE: 'fleet_size',
    NUMBER_OF_EMPLOYEES: 'number_of_employees',
    MESSAGE: 'message',
    TERM_CONDITIONS: 'term_conditions',
}
export default {
    name: "contact-request-form",
    mixins: [FormErrors],
    props: {collect_business_data: {type: Boolean, default: false}},

    data() {
        return {
            CONTACT_REQUEST_KEYS: CONTACT_REQUEST_KEYS,
            processingRequest: false,
            processingErrors: null,
            contact_request: {
                origin_url: null,
                name: null,
                phone: null,
                email: null,
                company_name: null,
                fleet_size: null,
                number_of_employees: null,
                message: null,
                term_conditions: false,
                collect_business_data: this.collect_business_data,
                formErrors: {},
            },
        }
    },

    methods: {
        t_form,

        contactRequestSubmit() {
            var vue_data = this
            var contact_request = this.contact_request;
            contact_request.origin_url = window.location.href;
            vue_data.processingRequest = true;
            api.post("/contact_requests", {
                contact_request: contact_request,
            }).then((response) => {
                console.log('CONTACT_REQUEST - Response: ', response.data)

                if (response.data.error) {
                    contact_request.formErrors = response.data.errors;
                } else {
                    $('.contact-request .text-section .success-message').removeClass('d-none');
                    $('.contact-request .text-section .hide-on-success').addClass('d-none');
                }
                vue_data.processingErrors = null;
                vue_data.processingRequest = false;
            }).catch((error) => {
                vue_data.processingErrors = error;
                vue_data.processingRequest = false;
            })
        },
    },
    computed: {
        // needed by form-errors mixin
        formErrors() {
            return this.contact_request.formErrors;
        },
        formErrorsPaths() {
            return [''];
        },
        processing() {
            return this.processingRequest;
        },
    },

}
</script>

